import axios from 'axios';

export const EdgeDeviceCustumTagApiCall = async (data, setEdCustumTagStatus,setEdCustumTagMessage) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "push_custom_field_tags_for_msp.php" : "push_custom_field_tags.php"; 
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "data":
       { "entity": data.entity, 
        "tag_name": data.name,
        "is_mandatory": data.isMandatory,
        "default_value": data.defaultValue || null,
        "data_type" : data.dataType
    }
    });
    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "data": 
        { "entity": data.entity, 
            "tag_name": data.name,
            "is_mandatory": data.isMandatory,
            "default_value": data.defaultValue || null,
            "data_type" : data.dataType
        }

    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    
     await axios.post(baseUrl, payload).then((response) =>{
        if (response.data.code === 1) {
            setEdCustumTagStatus(response.data.code);
            setEdCustumTagMessage(response.data.message);
        } else {
            setEdCustumTagStatus(response.data.code); 
            setEdCustumTagMessage(response.data.message);
        }
     })
    
};
    