import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, FormHelperText, FormControlLabel, Divider, DialogContentText, FormLabel, Radio, RadioGroup, Snackbar, Alert } from '@mui/material';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// import { EdgeDeviceCustumTagApiCall } from './EdgeDeviceCustumTagApiCall';
import UpdateCustomTagApiCall from './UpdateCustomTagApiCall';

// {openUpdateDialog && <UpdateCustomTag data={data} openUpdateDialog={openUpdateDialog} setOpenUpdateDialog={setOpenUpdateDialog} setUpdateStatus={setUpdateStatus} setUpdateMessage={setUpdateMessage} />}

export default function UpdateCustomTagDialog(props) {
    const { data, openUpdateDialog, setOpenUpdateDialog, setUpdateStatus, setUpdateMessage } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const [isMandatory, setIsMandatory] = useState(data?.is_mandatory === "Yes" ? 2 : 3);
    const [Name, setName] = useState(data.tags || "");
    const [selectedEntity, setSelectedEntity] = useState(data.entity || "Device");
    const [defaultValue, setDefaultValue] = useState(data.default_value || "");
    const [isMandatoryHelperText, setIsMandatoryHelperText] = useState('');
    const [NameHelperText, setNameHelperText] = useState("");
    const [selectedEntityHelperText, setSelectedEntityHelperText] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState('');
    const [error, setError] = useState("");
    const [selectedDataType, setSelectedDataType] = useState(data.data_type || "");
    const [selectedDataTypeHelperText, setSelectedDataTypeHelperText] = useState("")
    const [dataTypeError, setDataTypeError] = useState("");
    const [defaultValueHelperText, setDefaultValueHelperText] = useState("");

    useEffect(() => {

        if (data) {
            setIsMandatory(data.is_mandatory === "Yes" ? 2 : 3);
            setName(data.tags || "");
            setSelectedEntity(data.entity || "Device");
            setDefaultValue(data.default_value || "");
            setSelectedDataType(data.data_type || "")
        }
    }, [data, openUpdateDialog]);



    const handleChange = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case "name-input":
                setName(value);
                if (value.trim() === "") {
                    setNameHelperText("Name is required");
                } else {
                    setNameHelperText("");
                }
                break;

            case "entity-select":
                setSelectedEntity(value);
                if (value) {
                    setError(false);
                    setSelectedEntityHelperText("");
                } else {
                    setError(true);
                    setSelectedEntityHelperText("Please select an entity");
                }
                break;

            case "datatype-select":
                setSelectedEntity(value)
                if (value) {
                    setDataTypeError(false);
                    setSelectedDataTypeHelperText("");
                } else {
                    setDataTypeError(true);
                    setSelectedDataTypeHelperText("Please select DataType");
                }
                break;

            default:
                break;
        }
    };
    const handleChangeDefaultValue = (e) => {
        const value = e.target.value;
        setDefaultValue(value);

        switch (selectedDataType) {
            // 
            case "Int":
                if (value.trim() === "") {
                    setDefaultValueHelperText(""); // No error if the input is cleared
                } else if (!/^-?\d+$/.test(value)) {
                    setDefaultValueHelperText("Please enter a valid integer.");
                } else {
                    setDefaultValueHelperText("");
                }
                break;
            case "Float":
                if (!/^-?\d*(\.\d+)?$/.test(value)) {
                    setDefaultValueHelperText("Please enter a valid float.");
                } else {
                    setDefaultValueHelperText("");
                }
                break;
            case "Varchar":
                setDefaultValueHelperText(""); // No validation for varchar
                break;

            default:
                setDefaultValueHelperText("");
                break;
        }
    };
    const handleDateChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            setDefaultValue(newValue); // Set the value as a Dayjs object
            setDefaultValueHelperText("");
        } else {
            setDefaultValue(null);
            setDefaultValueHelperText("Please select a valid date and time.");
        }
    };
    const handleDataTypeChange = (e) => {
        const newDataType = e.target.value;
        setSelectedDataType(newDataType);

        // Reset defaultValue and its helper text whenever the data type changes
        setDefaultValue("");
        setDefaultValueHelperText("");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (Name === "") {
            setNameHelperText("Name is required");
            return;
        }

        if (selectedEntity === "") {
            setError(true);
            setSelectedEntityHelperText("Entity is required");
            return;
        }
        if (selectedDataType === "") {
            setDataTypeError(true);
            setSelectedDataTypeHelperText("DataType is Required");
            return;
        }
        let formattedDefaultValue = defaultValue;


        if (selectedDataType === 'DATETIME' && defaultValue) {
            formattedDefaultValue = dayjs(defaultValue).format('YYYY-MM-DD HH:mm:ss');
        }

        const updateddata = {
            tag_id: data.id, // assuming 'id' is the identifier for the tag
            entity: selectedEntity,
            tags: Name,
            is_mandatory: isMandatory === 2 ? "Yes" : "No",
            default_value: defaultValue || null,
            data_type: selectedDataType
        };
        // console.log("updatted data--", updateddata);

        await UpdateCustomTagApiCall(data, updateddata, setUpdateStatus, setUpdateMessage);
        setOpenUpdateDialog(false);
    };

    return (
        <>
            <Dialog
                open={openUpdateDialog}
                onClose={() => { setOpenUpdateDialog(false) }}
                fullWidth
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Update Custom Tag

                    <IconButton onClick={() => { setOpenUpdateDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <form>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1, px: 12 }}>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} error={error}>
                                <InputLabel id="entity-select-label">Entity</InputLabel>
                                <Select
                                    labelId="entity-select-label"
                                    id="entity-select"
                                    value={selectedEntity}
                                    style={{ width: "400px" }}
                                    label="Entity"
                                    onChange={handleChange}
                                    disabled
                                >
                                    <MenuItem value="Device">Device</MenuItem>
                                    <MenuItem value="Link">Link</MenuItem>
                                    <MenuItem value="Site">Site</MenuItem>
                                    <MenuItem value="Vendor">Vendor</MenuItem>
                                </Select>
                                {error && <Typography variant="caption" color="error">{selectedEntityHelperText}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>

                            <FormControl sx={{ m: 1, minWidth: 120 }} error={!!dataTypeError}>
                                <InputLabel id="datatype-select-label">Data Type</InputLabel>
                                <Select
                                    labelId="datatype-select-label"
                                    id="datatype-select"
                                    value={selectedDataType}
                                    style={{ width: "400px" }}
                                    label="DataType"
                                    // onChange={(e) => {
                                    //     setSelectedDataTypeHelperText("");
                                    //     setSelectedDataType(e.target.value);
                                    //     setDataTypeError(false);
                                    // }}
                                    onChange={handleDataTypeChange}


                                >
                                    <MenuItem value="Int">INT</MenuItem>
                                    <MenuItem value="Float">FLOAT</MenuItem>
                                    <MenuItem value="Varchar">VARCHAR</MenuItem>
                                    <MenuItem value="DATETIME">DATETIME</MenuItem>

                                </Select>
                                {dataTypeError && <Typography variant="caption" color="error">{selectedDataTypeHelperText}</Typography>}
                            </FormControl>

                        </Grid>


                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    id="name-input"
                                    label="Name"
                                    variant="outlined"
                                    value={Name}
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    style={{ width: "400px" }}
                                    helperText={NameHelperText}
                                    error={!!NameHelperText}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl component="fieldset" sx={{ m: 1, minWidth: 120, px: 1 }}>
                                <FormLabel component="legend">Is Mandatory</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="isMandatory"
                                    name="is-Mandatory"
                                    value={isMandatory}
                                    onChange={(e) => setIsMandatory(Number(e.target.value))}
                                >
                                    <FormControlLabel value={2} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={3} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    id="Default-Value"
                                    label="Default Value (optional)"
                                    variant="outlined"
                                    value={defaultValue}
                                    placeholder="Enter Value"
                                    onChange={(e) => setDefaultValue(e.target.value)}
                                    fullWidth
                                    style={{ width: "400px" }}
                                />
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                {selectedDataType === 'DATETIME' ? (

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="Default Value (optional)"
                                                format="YYYY-MM-DD HH:mm:ss"
                                                value={defaultValue ? dayjs(defaultValue) : null}
                                                sx={{ width: '400px' }}

                                                fullWidth
                                                onChange={handleDateChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        sx={{ width: '400px' }}
                                                        error={!!defaultValueHelperText}
                                                        helperText={defaultValueHelperText || 'YYYY-MM-DD HH:mm:ss'}
                                                    />
                                                )}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                ) : (
                                    <TextField
                                        id="default-value"
                                        label="Default Value (optional)"
                                        variant="outlined"
                                        value={defaultValue}
                                        placeholder="Enter Value"
                                        onChange={handleChangeDefaultValue}
                                        fullWidth
                                        style={{ width: "400px" }}
                                        helperText={defaultValueHelperText}
                                        error={!!defaultValueHelperText}
                                    />
                                )}
                            </FormControl>
                        </Grid>

                    </Grid>
                </form>
                <DialogActions>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Update
                    </Button>
                    <Button onClick={() => { setOpenUpdateDialog(false); }} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
