import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


// Api Import
import EditVendorApiCall from './EditVendorApiCall';


export default function EditVendorDialog(props) {
    const { value, openEditVendorDialog, setOpenEditVendorDialog, setEditVendorStatus, setEditVendorMessage } = props;

    const [vendorId, setVendorId] = useState(value.vendor_id);
    const [vendorName, setVendorName] = useState(value.vendor_name);
    const [vendorAddress, setVendorAddress] = useState(value.vendor_address);
    const [vendorContact, setVendorContact] = useState(value.vendor_phone_no);
    const [helpDeskEmail, setHelpDeskEmail] = useState(value.helpdesk_email);
    const [escalationLevel1Mail, setEscalationLevel1Mail] = useState(value.escalation_email_1);
    const [escalationLevel2Mail, setEscalationLevel2Mail] = useState(value.escalation_email_2);
    const [vendorCity, setVendorCity] = useState(value.city);
    const [promisedUptime, setPromisedUptime] = useState(value.promised_uptime);
    const [resolutionTime, setResolutionTime] = useState(value.resolution_time);

    const [vendorNameHelperText, setVendorNameHelperText] = useState("");
    const [vendorAddressHelperText, setVendorAddressHelperText] = useState("");
    const [vendorContactHelperText, setVendorContactHelperText] = useState("");
    const [helpDeskEmailHelper, setHelpDeskEmailHelper] = useState("");
    const [escalationLevel1MailHelper, setEscalationLevel1MailHelper] = useState("");
    const [escalationLevel2MailHelper, setEscalationLevel2MailHelper] = useState("");
    const [vendorCityHelper, setVendorCityHelper] = useState("");
    const [promisedUptimeHelper, setPromisedUptimeHelper] = useState("");
    const [resolutionTimeHelper, setResolutionTimeHelper] = useState("");

    const vendorNameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
    const vendorAddressRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*[A-Za-z\d]$/;
    const vendorContactRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cityRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*$/;
    const uptimeRegex = /^(\d{1,2}(\.\d{1,2})?|(\.\d)?)$/;
    const resolutionTimeRegex = /^(?:\d{1,2}|100)$/;

    const handleOnClose = () => {
        setOpenEditVendorDialog(false);
    }
// console.log("value--", value);

    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "vendor_name":
                if (!e.target.value.match(vendorNameRegex)) {
                    setVendorNameHelperText("Vendor Name Should be Valid");
                } else {
                    setVendorNameHelperText("");
                }
                setVendorName(e.target.value);
                break;
            case "vendor_address":
                if (!e.target.value.match(vendorAddressRegex)) {
                    setVendorAddressHelperText("Vendor Address Should be Valid");
                } else {
                    setVendorAddressHelperText("");
                }
                setVendorAddress(e.target.value);
                break;
            case "vendor_contact":
                if (!e.target.value.match(vendorContactRegex)) {
                    setVendorContactHelperText("Vendor Contact Should be Valid");
                } else {
                    setVendorContactHelperText("");
                }
                setVendorContact(e.target.value);
                break;
            case "help_desk_email":
                if (!e.target.value.match(emailRegex)) {
                    setHelpDeskEmailHelper("Enter Valid Email ID");
                } else {
                    setHelpDeskEmailHelper("");
                }
                setHelpDeskEmail(e.target.value);
                break;
            case "escalation_level1_mail":
                if (!e.target.value.match(emailRegex)) {
                    setEscalationLevel1MailHelper("Enter Valid Email ID");
                } else {
                    setEscalationLevel1MailHelper("");
                }
                setEscalationLevel1Mail(e.target.value);
                break;
            case "escalation_level2_mail":
                if (!e.target.value.match(emailRegex)) {
                    setEscalationLevel2MailHelper("Enter Valid Email ID");
                } else {
                    setEscalationLevel2MailHelper("");
                }
                setEscalationLevel2Mail(e.target.value);
                break;
            case "vendor_city":
                if (!e.target.value.match(cityRegex)) {
                    setVendorCityHelper("Enter Valid City Name");
                } else {
                    setVendorCityHelper("");
                }
                setVendorCity(e.target.value);
                break;
            case "promised_uptime":
                if (e.target.value === "" || !e.target.value.match(uptimeRegex)) {
                    setPromisedUptimeHelper("Enter Valid Promised Uptime");
                } else {
                    setPromisedUptimeHelper("");
                }
                setPromisedUptime(e.target.value);
                break;
            case "resolution_time":
                if (!e.target.value.match(resolutionTimeRegex)) {
                    setResolutionTimeHelper("Enter Valid Resolution Time");
                } else {
                    setResolutionTimeHelper("");
                }
                setResolutionTime(e.target.value);
                break;
            default:
                setResolutionTime(e.target.value);
                break;
        }
    };
    const handleOnSave = (e) => {
        e.preventDefault();
        if (!vendorName.match(vendorNameRegex)) {
            setVendorNameHelperText("Vendor Name Should be Valid");
            return;
        }
        if (!String(vendorAddress).match(vendorAddressRegex)) {
            setVendorAddressHelperText("Vendor Address Should be Valid");
            return;
        }
        if (!String(vendorContact).match(vendorContactRegex)) {
            setVendorContactHelperText("Phone Number should be Valid");
            return;
        }
        if (!helpDeskEmail.match(emailRegex)) {
            setHelpDeskEmailHelper("Invalid email address");
            return;
        }
        if (!String(vendorCity).match(cityRegex)) {
            setVendorCityHelper("Enter Correct City Name");
            return;
        }
        if (promisedUptime.length === 0 || !promisedUptime.toString().match(uptimeRegex)) {
            setPromisedUptimeHelper("Enter Valid Promised Uptime");
            return;
        }
        if (resolutionTime.length === 0  || !resolutionTime.toString().match(resolutionTimeRegex)) {
            setResolutionTimeHelper("Enter Valid Resolution Time");
            return;
        }
        EditVendorApiCall(vendorId, vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime, setEditVendorStatus, setEditVendorMessage);
        handleOnClose();
    }
    return (
        <>
            <Dialog open={openEditVendorDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Update Vendor
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Vendor Name" id="vendor_name" value={vendorName} onChange={handleOnChange} helperText={vendorNameHelperText} error={!!vendorNameHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Address" id="vendor_address" value={vendorAddress} onChange={handleOnChange} helperText={vendorAddressHelperText} error={!!vendorAddressHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Contact Number" id="vendor_contact" value={vendorContact} onChange={handleOnChange} helperText={vendorContactHelperText} error={!!vendorContactHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Help Desk Email" id="help_desk_email" value={helpDeskEmail} onChange={handleOnChange} helperText={helpDeskEmailHelper} error={!!helpDeskEmailHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-1 Email" id="escalation_level1_mail" value={escalationLevel1Mail} onChange={handleOnChange} helperText={escalationLevel1MailHelper} error={!!escalationLevel1MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-2 Email" id="escalation_level2_mail" value={escalationLevel2Mail} onChange={handleOnChange} helperText={escalationLevel2MailHelper} error={!!escalationLevel2MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="City" id="vendor_city" value={vendorCity} onChange={handleOnChange} helperText={vendorCityHelper} error={!!vendorCityHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Promised Uptime" id="promised_uptime" value={promisedUptime} onChange={handleOnChange} helperText={promisedUptimeHelper} error={!!promisedUptimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Resolution Time" id="resolution_time" value={resolutionTime} onChange={handleOnChange} helperText={resolutionTimeHelper} error={!!resolutionTimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
