// @mui
import {
    Box,
    Button,
    Card,
    ButtonGroup,
    Typography,
    Grid,
    FormControlLabel,
    Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSVLink, CSVDownload } from "react-csv";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { useState, useEffect, useRef, createRef, useContext } from 'react';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
import JsPDF from "jspdf";
// import { useIdleTimer } from 'react-idle-timer';

import "jspdf-autotable";
// components
import { $CombinedState } from 'redux';

import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';

import BestVendorsTable from "./BestComponents/BestVendorsTable"
import BestPerformingSitesTable from './BestComponents/BestPerformingSitesTable';
import BestPerformingLinksTable from './BestComponents/BestPerformingLinksTable';

import WorstVendorsTable from './WorstComponents/WorstVendorsTable';
import WorstPerformingSitesTable from './WorstComponents/WorstPerformingSitesTable';
import WorstPerformingLinksTable from './WorstComponents/WorstPerformingLinksTable';

import HollisticLinkView from './HollisticComponents/HolisticLinkView';
import HollisticVendorView from './HollisticComponents/HolisticVendorView';
import HolisticSitesView from './HollisticComponents/HolisticSitesView';
import HolisticDevicesView from './HollisticComponents/HolisticDevicesView';
import MonthYearPicker from './MonthYearPicker';
import FileSystemTreeView from './FileSystemTreeView';

import BestVendorsApiCall from './BestVendorsApiCall';
import BestSitesApiCall from './BestSitesApiCall';
import BestLinksApiCall from './BestLinksApiCall';
import ReportsDialogComponent from './ReportsDialogComponent';
import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';
import BestDevicesApiCall from './BestDevicesApiCall';
import { newText } from '../SummaryComponents/Components/new';

const useStyles = makeStyles({
    boxPadding: {
        textDecoration: 'none',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

const ColoredSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        // Default color when the switch is off (left)
        color: '#66bb6a', // Green color
        '& + .MuiSwitch-track': {
            backgroundColor: '#66bb6a',
        },
        // Color when checked (right)
        '&.Mui-checked': {
            color: '#42a5f5', // Blue color
            '& + .MuiSwitch-track': {
                backgroundColor: '#42a5f5',
            },
        },
    },
    // Style the track
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
    },
}));

export default function ReportsMainComponent() {

    const classes = useStyles();
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const [value, setValue] = useState(0);
    const [holisticValue, setHolisticValue] = useState('0');

    const [linkVariant, setLinkVariant] = useState('contained');
    const [vendorVariant, setVendorVariant] = useState('outlined');
    const [siteVariant, setSiteVariant] = useState('outlined')
    const [deviceVariant, setDeviceVariant] = useState('outlined')

    const [monthYear, setMonthYear] = useState(() => {
        const date = new Date();
        const month = date.toLocaleString("en-IN", { month: "2-digit" });
        const year = date.toLocaleString("en-IN", { year: "numeric" });
        return `${year}-${month}`;
    });

    const [bestVendors, setBestVendors] = useState([]);
    const [bestSites, setBestSites] = useState([]);
    const [bestLinks, setBestLinks] = useState([]);
    const [bestDevices, setBestDevices] = useState([]);

    const [linkData, setLinkData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    const [siteHeader, setSiteHeader] = useState([])
    const [linkheader, setLinkHeader] = useState([]);
    const [vendorHeader, setVendorHeader] = useState([]);
    const [deviceHeader, setDeviceheader] = useState([]);

    const [openReportsDialog, setOpenReportDialog] = useState(false);
    const [vendorReports, setVendorReports] = useState(false);
    const [siteReports, setSiteReports] = useState(false)
    const [linkReports, setLinkReport] = useState(false);
    const [deviceReports, setDeviceReport] = useState(false);

    const [reportType, setReportType] = useState("1");
    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    const [switchState, setSwitchState] = useState(false);

    const [linkReportData, setLinkReportData] = useState({});
    const [vendorReportData, setVendorReportData] = useState({});
    const [siteReportData, setSiteReportData] = useState({});
    const [deviceReportData, setDeviceReportData] = useState({});

    const [showDownloadOption, setShowDownloadOption] = useState(true)

    const csvLinkVendors = createRef();
    const csvLinkSites = createRef();
    const csvLinkLinks = createRef();
    const csvLinkDevices = createRef();

    const handleSwitchChange = (e) => {
        setSwitchState((prevState) => {
            const newState = !prevState;
            if (!newState) {
                setBestLinks(linkReportData.bh_data)
                setBestVendors(vendorReportData.bh_data)
                setBestSites(siteReportData.bh_data)
                setBestDevices(deviceReportData.bh_data)
            } else {
                setBestLinks(linkReportData.all_data)
                setBestVendors(vendorReportData.all_data)
                setBestSites(siteReportData.all_data)
                setBestDevices(deviceReportData.all_data)
            }
            return newState;
        });

    }

    const handleChange = (event, newValue) => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        if (newValue === 1) {
            setShowDownloadOption(false)
        } else {
            setShowDownloadOption(true)
        }
        setValue(newValue);
    };

    function TabPanel(props) {

        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {!(globalState === custId && mspStatus === 1) && value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    const handleHolisticChange = (e) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }

        setHolisticValue(e.target.value);
        if (e.target.value === "0") {
            setLinkVariant('contained')
            setSiteVariant('outlined');
            setVendorVariant('outlined');
            setDeviceVariant('outlined');
        }
        if (e.target.value === "1") {
            setLinkVariant('outlined')
            setSiteVariant('outlined');
            setVendorVariant('contained');
            setDeviceVariant('outlined');
        }
        if (e.target.value === "2") {
            setLinkVariant('outlined')
            setSiteVariant('contained');
            setVendorVariant('outlined');
            setDeviceVariant('outlined');
        }
        if (e.target.value === "3") {
            setLinkVariant('outlined')
            setSiteVariant('outlined');
            setVendorVariant('outlined');
            setDeviceVariant('contained');
        }


        // console.log(siteVariant);
    }

    const convertMinToHourMinutes = (minutes) => {
        const d = Math.round(Math.floor(minutes / (24 * 60)));
        const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
        const m = Math.round((minutes % (24 * 60)) % 60);
        if (minutes === "0") {
            return "No Impact";
        }

        return `${d}days, ${h}hours, ${m}minutes`;


    }

    const generateVendorReport = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const doc = new JsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Vendor Report";

        // const data = bestVendors;
        // console.log(bestVendors)
        const vendorArr = [];
        for (let index = 0; index < vendorReportData.all_data.length; index += 1) {
            const vendor = [];
            vendor[0] = index + 1;
            vendor[1] = vendorReportData.all_data[index].vendor;
            vendor[2] = vendorReportData.bh_data[index].down_cnt;
            vendor[3] = vendorReportData.bh_data[index].up_percent.toString().concat('%');
            vendor[4] = vendorReportData.bh_data[index].down_percent.toString().concat('%');
            vendor[5] = vendorReportData.bh_data[index].ttr_breach_cnt;
            vendor[6] = vendorReportData.all_data[index].down_cnt;
            vendor[7] = vendorReportData.all_data[index].up_percent.toString().concat('%');
            vendor[8] = vendorReportData.all_data[index].down_percent.toString().concat('%');
            vendor[9] = vendorReportData.all_data[index].ttr_breach_cnt;
            vendorArr.push(vendor)
        }

        const headers = [["Sl.No", "Vendor", "DownTime(Bus.Hr)", "Up Time(%)(Bus.hr)", "Down Time(%)(Bus.hr)", "TTR Breaches(Bus.Hr)", "DownTime(24X7)", "Up Time(%)(24X7)", "Down Time(%)(24X7)", "TTR Breaches(24X7)"]];
        
        // console.log(headers);
        const content = {
            startY: 50,
            head: headers,
            body: vendorArr
        };


        doc.setFont("helvetica", "normal");
        const marginLeft = 10;
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Vendor_Report_${monthYear}.pdf`)
    }

    const generateSiteReport = () => {
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 10;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Sites Report";

        const sitesArr = [];
        for (let index = 0; index < siteReportData.all_data.length; index += 1) {
            const site = [];
            site[0] = index + 1;
            site[1] = siteReportData.all_data[index].group_name;
            site[2] = siteReportData.all_data[index].location_name;
            site[3] = siteReportData.bh_data[index].down_cnt;
            site[4] = siteReportData.bh_data[index].up_percent.toString().concat(' %');
            site[5] = siteReportData.bh_data[index].down_percent.toString().concat(' %');
            site[6] = siteReportData.all_data[index].down_cnt;
            site[7] = siteReportData.all_data[index].up_percent.toString().concat(' %');
            site[8] = siteReportData.all_data[index].down_percent.toString().concat(' %');
            sitesArr.push(site)
        }

        const headers = [["Sl.No", "Group Name", "Site Name", "Down Time (Bus.Hr)", "Up Time(%)(Bus.Hr)", "Down Time(%)(Bus.Hr)", "Down Time (24X7)", "Up Time(%)(24X7)", "Down Time(%)(24X7)"]];

        const content = {
            startY: 50,
            head: headers,
            body: sitesArr
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Sites_Report_${monthYear}.pdf`)
    }

    const generateLinkReport = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        // const marginLeft = 40;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Link Report";

        const linksArr = [];
        for (let index = 0; index < linkReportData.all_data.length; index += 1) {
            const link = [];
            link[0] = index + 1;
            link[1] = linkReportData.all_data[index].circuit_id;
            link[2] = linkReportData.all_data[index].location;
            link[3] = linkReportData.all_data[index].vendor;
            link[4] = linkReportData.all_data[index].public_ip;
            link[5] = linkReportData.bh_data[index].down_cnt;
            link[6] = linkReportData.bh_data[index].down_percent.toString().concat(' %');
            link[7] = linkReportData.bh_data[index].up_percent.toString().concat(' %');
            link[8] = linkReportData.bh_data[index].ttr_breach_cnt;

            link[9] = linkReportData.all_data[index].down_cnt;
            link[10] = linkReportData.all_data[index].down_percent.toString().concat(' %');
            link[11] = linkReportData.all_data[index].up_percent.toString().concat(' %');
            link[12] = linkReportData.all_data[index].ttr_breach_cnt;
            linksArr.push(link)
        }
        // console.log(bestLinksArr);
        const headers = [
            [
                "Sl.No",
                "Circuit ID",
                "Site Name",
                "Vendor",
                "Public IP",
                "Downtime(Bus.Hr)",
                "Uptime(%)(Bus.Hr)",
                "Downtime(%)(Bus.Hr)",
                "TTR Breaches(Bus.hr)",
                "Downtime(24X7)",
                "Uptime(%)(24X7)",
                "Downtime(%)(24X7)",
                "TTR Breaches(24X7)"
            ]
        ];

        // Define the content object
        const content = {
            startY: 50,
            head: headers,
            body: linksArr,
        };

        doc.setFont("helvetica", "normal");

        const marginLeft = 10;
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);

        doc.save(`Link_Report_${monthYear}.pdf`);
    }

    const generateDeviceReport = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const unit = "pt";
        const size = "A2"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        // const marginLeft = 40;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Device Report";

        const devicesArr = [];
        for (let index = 0; index < deviceReportData.all_data.length; index += 1) {
            const device = [];
            device[0] = index + 1;
            device[1] = deviceReportData.all_data[index].host_name;
            device[2] = deviceReportData.bh_data[index].location_name;
            device[3] = deviceReportData.bh_data[index].device_ip;
            device[4] = deviceReportData.bh_data[index].down_time;
            device[5] = deviceReportData.bh_data[index].up_percent.toString().concat('%');
            device[6] = deviceReportData.bh_data[index].down_percent.toString().concat('%');
            device[7] = deviceReportData.all_data[index].down_time;
            device[8] = deviceReportData.all_data[index].up_percent.toString().concat('%');
            device[9] = deviceReportData.all_data[index].down_percent.toString().concat('%');
            devicesArr.push(device)
        }

        // console.log(bestLinksArr);
        const headers = [["Sl.No", "Host Name", "Location Name", "Device IP", "DownTime(Bus.Hr)", "Up Time(%)(Bus.Hr)", "Down Time(%)(Bus.Hr)", "DownTime(24X7)", "Up Time(%)(24X7)", "Down Time(%)(24X7)"]];

        // Define the content object
        const content = {
            startY: 50,
            head: headers,
            body: devicesArr,
        };

        doc.setFont("helvetica", "normal");

        const marginLeft = 10;
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);

        doc.save(`Link_Report_${monthYear}.pdf`);
    }

    const generateLinkReportCSV = (links) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const linksArr = [];

        for (let index = 0; index < links.all_data.length; index += 1) {
            const link = [];
            link[0] = index + 1;
            link[1] = links.all_data[index].circuit_id;
            link[2] = links.all_data[index].location;
            link[3] = links.all_data[index].vendor;
            link[4] = links.all_data[index].public_ip;
            link[5] = links.bh_data[index].down_cnt;
            link[6] = links.bh_data[index].down_percent.toString().concat(' %');
            link[7] = links.bh_data[index].up_percent.toString().concat(' %');
            link[8] = links.bh_data[index].ttr_breach_cnt;

            link[9] = links.all_data[index].down_cnt;
            link[10] = links.all_data[index].down_percent.toString().concat(' %');
            link[11] = links.all_data[index].up_percent.toString().concat(' %');
            link[12] = links.all_data[index].ttr_breach_cnt;
            linksArr.push(link)

        }
        const headers = ["Sl.No", "Circuit ID", "Site Name", "Vendor", "Public IP", "Downtime(Bus.Hr)", "Uptime(%)(Bus.Hr)", "Downtime(%)(Bus.Hr)", "TTR Breaches(Bus.hr)", "Downtime(24X7)", "Uptime(%)(24X7)", "Downtime(%)(24X7)", "TTR Breaches(24X7)"];
        setLinkHeader(headers);

        setLinkData(linksArr);
    }

    const generateSiteReportCSV = (sites) => {
        const sitesArr = [];
        for (let index = 0; index < sites.all_data.length; index += 1) {
            const site = [];
            site[0] = index + 1;
            site[1] = sites.all_data[index].group_name;
            site[2] = sites.all_data[index].location_name;
            site[3] = sites.bh_data[index].down_cnt;
            site[4] = sites.bh_data[index].up_percent.toString().concat(' %');
            site[5] = sites.bh_data[index].down_percent.toString().concat(' %');
            site[6] = sites.all_data[index].down_cnt;
            site[7] = sites.all_data[index].up_percent.toString().concat(' %');
            site[8] = sites.all_data[index].down_percent.toString().concat(' %');
           
            sitesArr.push(site)
        }

        const headers = ["Sl.No", "Group Name", "Site Name", "Down Time (Bus.Hr)", "Up Time(%)(Bus.Hr)", "Down Time(%)(Bus.Hr)", "Down Time (24X7)", "Up Time(%)(24X7)", "Down Time(%)(24X7)"];

        setSiteHeader(headers)
        setSiteData(sitesArr);
    }

    const generateVendorReportCSV = (vendors) => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const vendorArr = [];
        for (let index = 0; index < vendors.all_data.length; index += 1) {
            const vendor = [];
            vendor[0] = index + 1;
            vendor[1] = vendors.all_data[index].vendor;
            vendor[2] = vendors.bh_data[index].down_cnt;
            vendor[3] = vendors.bh_data[index].up_percent.toString().concat('%');
            vendor[4] = vendors.bh_data[index].down_percent.toString().concat('%');
            vendor[5] = vendors.bh_data[index].ttr_breach_cnt;
            vendor[6] = vendors.all_data[index].down_cnt;
            vendor[7] = vendors.all_data[index].up_percent.toString().concat('%');
            vendor[8] = vendors.all_data[index].down_percent.toString().concat('%');
            vendor[9] = vendors.all_data[index].ttr_breach_cnt;
            vendorArr.push(vendor)
        }

        const headers = ["Sl.No", "Vendor", "DownTime(24X7)", "Up Time(%)(Bus.hr)", "Down Time(%)(Bus.hr)", "TTR Breaches(Bus.Hr)", "DownTime(24X7)", "Up Time(%)(24X7)", "Down Time(%)(24X7)", "TTR Breaches(24X7)"];
        setVendorHeader(headers)
        setVendorData(vendorArr);
    }

    const generateDeviceReportCSV = (devices) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const devicesArr = [];
        for (let index = 0; index < devices.all_data.length; index += 1) {
            const device = [];
            device[0] = index + 1;
            device[1] = devices.all_data[index].host_name;
            device[2] = devices.bh_data[index].location_name;
            device[3] = devices.bh_data[index].device_ip;
            device[4] = devices.bh_data[index].down_time;
            device[5] = devices.bh_data[index].up_percent.toString().concat('%');
            device[6] = devices.bh_data[index].down_percent.toString().concat('%');
            device[7] = devices.all_data[index].down_time;
            device[8] = devices.all_data[index].up_percent.toString().concat('%');
            device[9] = devices.all_data[index].down_percent.toString().concat('%');
           
            devicesArr.push(device)
        }

        const headers = ["Sl.No", "Host Name", "Location Name", "Device IP", "DownTime(Bus.Hr)", "Up Time(%)(Bus.Hr)", "Down Time(%)(Bus.Hr)", "DownTime(24X7)", "Up Time(%)(24X7)", "Down Time(%)(24X7)"];
        setDeviceheader(headers)
        setDeviceData(devicesArr);
    }

    const setPresentMonthYear = async () => {
        const date = new Date();
        const month = date.toLocaleString("en-IN", { month: "2-digit" });
        const year = date.toLocaleString("en-IN", { year: "numeric" });
        setMonthYear(`${year}-${month}`)
    }

    useEffect(() => {
        setPresentMonthYear();
    }, [])


    const _setBestDevicesData = async () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        await BestDevicesApiCall(buState, setBestDevices, setDeviceReportData, monthYear, generateDeviceReportCSV);
    }

    const _setBestVendorsData = async () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        await BestVendorsApiCall(buState, setBestVendors, setVendorReportData, monthYear, generateVendorReportCSV);
    }

    const _setBestSitesData = async () => {
        await BestSitesApiCall(buState, setBestSites, setSiteReportData, monthYear, generateSiteReportCSV);
    }

    const _setBestLinksData = async () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        await BestLinksApiCall(buState, setBestLinks, setLinkReportData, monthYear, generateLinkReportCSV);
    }


    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        _setBestVendorsData();
    }, [monthYear, globalState, buState])

    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        _setBestDevicesData();
    }, [monthYear, globalState, buState])

    useEffect(() => {
        _setBestSitesData();
    }, [monthYear, globalState, buState])


    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        _setBestLinksData();
    }, [monthYear, globalState, buState])

    const handleReportClose = () => {
        if (reportType === "1") {
            if (vendorReports === true) {
                generateVendorReport();
            }
            if (siteReports) {
                generateSiteReport();
            }
            if (linkReports) {
                generateLinkReport();
            }
            if (deviceReports) {
                generateDeviceReport();
            }
        }
        else {
            if (vendorReports === true) {
                csvLinkVendors.current.link.click();
            }
            if (siteReports === true) {
                csvLinkSites.current.link.click();
            }
            if (linkReports) {
                csvLinkLinks.current.link.click();
            }
            if (deviceReports) {
                csvLinkDevices.current.link.click();
            }
        }
        setReportType("1");
        setVendorReports(false);
        setSiteReports(false);
        setLinkReport(false)
        setOpenReportDialog(false);
    }

    const generateReports = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        setOpenReportDialog(true)
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
                <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: 1 }}>
                    <div className={classes.titleStyle}>
                        <Typography variant='h4'>
                            <strong>Reports</strong>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} sx={{ px: 1, py: 1 }}>
                    <MonthYearPicker setMonthYear={setMonthYear} monthYear={monthYear} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 1, py: 1 }}>
                    <Box display="flex" alignItems="center">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Reports" />
                            <Tab label="Download" />
                        </Tabs>

                        {showDownloadOption && <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 32px',
                                border: '1px solid #ccc',
                                borderRadius: '12px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#f9f9f9',
                                margin: '0 auto', // Center the box
                            }}>
                            <Typography variant="body1" style={{ marginRight: '8px' }}>Business Hr Data</Typography>

                            <ColoredSwitch
                                checked={switchState}
                                onChange={handleSwitchChange}
                            />

                            <Typography variant="body1" style={{ marginLeft: '8px' }}>24X7 Data</Typography>
                        </Box>}
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Grid container sx={{ paddingTop: "20px" }}>
                            <Grid item xs={2} sm={2} md={2} lg={2} >
                                <ButtonGroup size="large" aria-label="outlined primary button group">
                                    <Button value="0" variant={linkVariant} onClick={handleHolisticChange}>Link</Button>
                                    <Button value="1" variant={vendorVariant} onClick={handleHolisticChange}>Vendor</Button>
                                    <Button value="2" variant={siteVariant} onClick={handleHolisticChange}>Sites</Button>
                                    <Button value="3" variant={deviceVariant} onClick={handleHolisticChange}>Devices</Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} >
                                {''}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                <Button size="large" variant="contained" onClick={generateReports} sx={{ mx: 1 }}>Generate Reports</Button>
                            </Grid>
                        </Grid>
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '0' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HollisticLinkView bestLinks={bestLinks} />
                            </Grid>
                        </Card>}
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '1' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HollisticVendorView bestVendors={bestVendors} />
                            </Grid>
                        </Card>}
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '2' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HolisticSitesView bestSites={bestSites} />
                            </Grid>
                        </Card>}
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '3' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HolisticDevicesView bestDevices={bestDevices} />
                            </Grid>
                        </Card>}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Card variant="outlined" elevation={15} sx={{ marginTop: "1%" }}>
                            <FileSystemTreeView monthYear={monthYear} />
                        </Card>
                    </TabPanel>

                    {openReportsDialog && <ReportsDialogComponent openReportsDialog={openReportsDialog} setOpenReportDialog={setOpenReportDialog}
                        setVendorReports={setVendorReports} setSiteReports={setSiteReports} setLinkReport={setLinkReport} setDeviceReport={setDeviceReport} setReportType={setReportType} handleReportClose={handleReportClose} />}

                    <CSVLink data={vendorData} headers={vendorHeader} filename={`Vendors_Report_${monthYear}.csv`} ref={csvLinkVendors} />
                    <CSVLink data={siteData} headers={siteHeader} filename={`Sites_Report_${monthYear}.csv`} ref={csvLinkSites} />
                    <CSVLink data={linkData} headers={linkheader} filename={`Links_Report_${monthYear}.csv`} ref={csvLinkLinks} />
                    <CSVLink data={deviceData} headers={deviceHeader} filename={`Devices_Report_${monthYear}.csv`} ref={csvLinkDevices} />
                </Grid>
                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
            </Grid>
        </Grid>
    );
}