import React, { useEffect, useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import UploadIcon from '@mui/icons-material/Upload';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

import Iconify from '../../Iconify';

import GetLocationsByClientApi from './GetLocationsByClientApi';
import EditLocationDialog from './EditLocationDialog';
import DeleteLocationDialog from './DeleteLocationDialog';
import AddEdgeDeviceDialog from './AddEdgeDeviceDialog';
import EdgeDeviceUploadDialog from './EdgeDeviceUploadDialog';
import DeleteEdgeDeviceDialog from './DeleteEdgeDeviceDialog';
import AddBusinessHoursDialog from './AddBusinessHoursDialog';
import PauseLocationApiCall from './PauseLocationApiCall';
import UtilizationAlertApiCall from './UtilizationAlert';

import PauseLocationDailog from './PauseLocationDailog';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import UtilizationAlertDailog from './UtilizationAlertDailog';
import MultipleSiteUploadDialog from './MultipleSiteUploadDialog';
import NotificationAlertSiteDialog from './NotificationAlertSiteDialog';



import { useGlobalState } from '../../../globalHooks/GlobalState';

const useStyles = makeStyles({
    container: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px', // Adjust the width as desired
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
    },
});

const headCells = [
    {
        id: 'slNo',
        numeric: true,
        disablePadding: true,
        label: 'Sl No',
    },
    {
        id: 'cust_name',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'locationName',
        numeric: false,
        disablePadding: true,
        label: 'Site Name',
    },
    {
        id: 'edgeDevice',
        numeric: false,
        disablePadding: true,
        label: 'Edge Device',
    },
    {
        id: 'bhStart',
        numeric: true,
        disablePadding: false,
        label: 'Business Hrs ',
    },
    // {
    //     id: 'bhEnd',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Business Hr End',
    // },
    {
        id: 'city',
        numeric: true,
        disablePadding: false,
        label: 'City',
    },
    // {
    //     id: 'customTags',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Custom Tags',
    // },
    
    {
        id: 'criticality',
        numeric: true,
        disablePadding: false,
        label: 'Site Criticality',
    },
    {
        id: 'pausestatus',
        numeric: true,
        disablePadding: false,
        label: 'Pause Status',
    },
    {
        id: 'UtilAlertStatus',
        numeric: true,
        disablePadding: false,
        label: 'Utilization Alert Status'
    },
    {
        id: 'remarks',
        numeric: false,
        disablePadding: false,
        label: 'Remarks',
    },
    {
        id: 'ispCount',
        numeric: true,
        disablePadding: false,
        label: 'No of Links',
    },
    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead({ showCustomer, tagDataKeys }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.slice(0, 6).map((headCell) => (
                    (headCell.id !== 'cust_name' || showCustomer) &&
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
                {tagDataKeys.map(key => (
            <TableCell
                key={key}
                align="left"
                padding="normal"
                sx={{ background: '#f0f0f0', padding: '10px' }}
            >
                {key} {/* Display tag_data key as the column header */}
            </TableCell>
        ))}
        {headCells.slice(6).map((headCell) => (
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    showCustomer: PropTypes.bool.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    tagDataKeys: PropTypes.array.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName, handleUploadClick, handleMultipleSiteUploadClick }) {
    return (
        <RootStyle>
            <Typography variant='h4'>
                Sites
            </Typography>
            {/* <Box
                width="78%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
                >
                <Button
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    sx={{ width: "250px", height: "40px" }}
                    onClick={handleUploadClick}
                    data-tut="linkeyetour_home_addmultipleedgedevices">
                    Upload Edge Devices
                </Button>
            </Box>
            <Box
                width="78%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
                 marginRight="50px"
                >
                <Button
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    sx={{ width: "350px", height: "40px" }}
                    onClick={handleMultipleSiteUploadClick}
                    data-tut="linkeyetour_home_addmultipleedgedevices">
                    Upload Multiple Location with Devices
                </Button>
            </Box> */}
            <Box
                width="78%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
                gap="30px"
            >

                <Button
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    sx={{ width: "250px", height: "40px" }}
                    onClick={handleUploadClick}
                    data-tut="linkeyetour_home_addmultipleedgedevices"
                >
                    Upload Edge Devices
                </Button>


                <Button
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    sx={{ width: "350px", height: "40px" }}
                    onClick={handleMultipleSiteUploadClick}
                    data-tut="linkeyetour_home_addmultipleedgedevices"
                >
                    Upload Multiple Location with Devices
                </Button>
            </Box>


            <SearchStyle
                id='searchSite'
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ViewLocationTable() {
    const isEngineer = (localStorage.getItem("role") === "2")
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [value, setValue] = useState({});
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [open, setOpen] = useState(false)
    const [severStatus, setSeverStatus] = useState("");

    const [deleteStatus, setDeleteStatus] = useState(0);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [openDeleteDialog, setOpenDeletDialog] = useState(false);

    const [addEdStatus, setAddEdStatus] = useState(0);
    const [openAddEdDailog, setOpenAddEdDailog] = useState(false);
    const [openAddEd, setOpenAddEd] = useState(false);

    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openMultipleSiteUploadDialog, setOpenMultipleUploadDialog] = useState(false);

    const [deleteEdStatus, setDeleteEdStatus] = useState(0);
    const [openDeleteEdDialog, setOpenDeleteEdDialog] = useState(false);
    const [openDeleteEd, setOpenDeleteEd] = useState(false);

    const [openBusinesHourDialog, setOpenBusinessHourDialog] = useState(false);
    const [addBusinessHrStatus, setAddBusinessHrStatus] = useState(0);
    const [openBusinessHrAlert, setOpenBusnessHrAlert] = useState(false);

    const [uploadEds, setUploadEds] = useState(false);

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspClientId = localStorage.getItem("cust_id");
    const regClientId = localStorage.getItem("reg_cust_id");
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
    const [openCustomerWarnDialog, setOpenCustomerWarnDialog] = useState(false);
    const [openPauseDialog, setOpenPauseDialog] = useState(false);
    const [pauseValue, setPauseValue] = useState(-1);
    const [pauseLocationId, setPauseLocationId] = useState(-1);
    const [pauseLocationName, setPauseLocationName] = useState(-1);
    const [pauseStatus, setPauseStatus] = useState(0);
    const [openPauseSnack, setOpenPauseSnack] = useState(false);
    const [updateMessage, setUpdateMessage] = useState("")
    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    const [UtilizationAlertDialog, setUtilizationAlertDialog] = useState(false);
    const [UtilizationOpen, setUtilizationOpen] = useState(false);
    const [UtilizationAlertValue, setUtilizationAlertValue] = useState(-1);
    const [utilizationLocationId, setUtilizationLocationId] = useState(-1);
    const [utilizationLocationName, setUtilizationLocationName] = useState("");
    const [utilizationAlertMessage, setUtilizationAlertMessage] = useState("");
    const [multipleSiteUploadStatus, setMultipleSiteUploadStatus] = useState(false);
    const [multipleSiteUploadMessage, setMultipleSiteUploadMessage] = useState("")
    const [alertNotifyLocationId, setAlertNotifyLocationId] = useState(-1);
    const [alertNotifyLocationName, setAlertNotifyLocationName] = useState("");
    const [alertNotifyPauseStatus, setAlertNotifyPauseStatus] = useState(-1);
    const [alertNotifyUtilAlertStatus, setAlertNotifyUtilAlertStatus] = useState(-1)
    const [openAlertNotifiDialog, setOpenAlertNotifiDialog] = useState(false);
    const [alertNotifyStatus, setAlertNotifyStatus] = useState(0);
    const [alertNotifyMessage, setAlertNotifyMessage] = useState("");
    const [warning, setWarning] = useState("");
    const [critical, setCritical] = useState("");
    const [openMulSite, setOpenMulSite] = useState(false)

    

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));


    const handleOnEdit = (e, row) => {
        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenWarnDialog(true);
            return;
        }
        resetAll();
        setOpenEditDialog(true);
        setValue(row);

    }

    const handleOnBusinessHourClick = (e, row) => {
        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenWarnDialog(true);
            return;
        }
        resetAll();
        setOpenBusinessHourDialog(true);
        setValue(row);
    }

    const handleDelete = (e, row) => {
        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenWarnDialog(true);
            return;
        }
        resetAll();
        setOpenDeletDialog(true);
        setValue(row);
    }

    const handleOnAddEd = (e, row) => {
        resetAll();
        setOpenAddEdDailog(true);
        setValue(row);
    }


    const handleFilterByName = async (event, searchedVal) => {

        setFilterName(event.target.value)
        setFilteredRows(rows);
        const ftrRows = rows.filter((row) => {
            const deviceIPs = row.device_ip.join(' ');
            return (
                row.location_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
                deviceIPs.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.city.toLowerCase().includes(searchedVal.toLowerCase())
            );
        });
        setFilteredRows(ftrRows);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const _GetLocationsByClientApi = async () => {
        await GetLocationsByClientApi(setRows, setFilteredRows, buState, setWarning, setCritical);
    }

    useEffect(() => {
        setFilterName('');
        _GetLocationsByClientApi();
    }, [globalState, buState, alertNotifyStatus]);

    useEffect(() => {
        setFilterName('');
        _GetLocationsByClientApi();
    }, [updateStatus, multipleSiteUploadStatus, alertNotifyStatus]);


    const formatHour = (hr) => (
        hr < 10 ? `0${hr}:00 hrs` : `${hr}:00 hrs`
    );
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
        setAlertNotifyStatus(0);
        setOpenAlertNotifiDialog(false);

    };
    const resetAll = () => {
        setDeleteEdStatus(0);
        setUpdateStatus(0);
        setDeleteStatus(0);
        setAddEdStatus(0);
        setAlertNotifyStatus(0);
        setSeverStatus("");
        setOpen(false);
        setOpenAddEd(false);
        setOpenDeleteEd(false);
        setDeleteOpen(false);
        setUploadEds(false);
        setOpenBusnessHrAlert(false);
        setOpenPauseSnack(false);
        setPauseStatus(false)
        setOpenAlertNotifiDialog(false);
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAll();
        setUtilizationOpen(false);
        setOpenMulSite(false)


    };

    const handleDataReLoad = () => {
        do {
            setFilterName('');
            _GetLocationsByClientApi();
        }
        while (updateStatus === 1 || deleteStatus === 1 || addEdStatus === 1 || deleteEdStatus === 1);
    };

    const handleUploadClick = () => {
        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenWarnDialog(true);
            return;
        }
        resetAll();
        setValue(rows);
        setOpenUploadDialog(true);
    }

    const handleMultipleSiteUploadClick = () => {
        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenWarnDialog(true);
            return;
        }
        resetAll();
        setValue(rows);
        setOpenMultipleUploadDialog(true)
    }

    const handleOnDeleteEd = (e, row) => {
        resetAll();
        setOpenDeleteEdDialog(true);
        setValue(row);
    }
    const handleUtilizationAlertDialog = (locationId, locationName, value) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        setUtilizationLocationId(locationId);
        setUtilizationLocationName(locationName);
        setUtilizationAlertValue(value)
        setUtilizationAlertDialog(true);
    }
    const handleUtilizationAlertLink = async (alertStatus) => {
        if (alertStatus === true) {
            await UtilizationAlertApiCall(utilizationLocationId, utilizationLocationName, UtilizationAlertValue, setUtilizationOpen);
            await GetLocationsByClientApi(setRows, setFilteredRows, buState);
        }
        setUtilizationAlertDialog(false);
        setPauseLocationId(-1);
        setPauseLocationName("");
    }
    // alert notify ---
    const handleAlertNotifyDailog = (locationId, locationName, pauseStatus, utilAlertStatus) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setAlertNotifyLocationId(locationId);
        setAlertNotifyLocationName(locationName);
        setAlertNotifyPauseStatus(pauseStatus);
        setAlertNotifyUtilAlertStatus(utilAlertStatus)

        setOpenAlertNotifiDialog(true)
    }



    const handlePauseDialog = (locationId, locationName, value) => {
        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenWarnDialog(true);
            return;
        }
        // if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
        //     setOpenSnackBar(true);
        //     setOpenCustomerWarnDialog(true);
        //     return;
        // }
        setPauseLocationId(locationId);
        setPauseLocationName(locationName);
        setPauseValue(value)
        setOpenPauseDialog(true);
    }

    const handlePauseLocation = async (pauseStatus) => {
        if (pauseStatus === true) {
            await PauseLocationApiCall(pauseLocationId, pauseLocationName, pauseValue, setPauseStatus, setOpenPauseSnack, setSeverStatus)
            await _GetLocationsByClientApi();
        }
        setOpenPauseDialog(false);
        setPauseLocationId(-1);
        setPauseLocationName("");
    }
    
    const tagDataKeys = [...new Set(filteredRows.flatMap(row => Object.keys(row.tag_data || {})))];
    return (
        <Box sx={{ width: '100%' }}>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    Please select a customer.
                </Alert>
            </Snackbar>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar
                    filterName={filterName}
                    onFilterName={(e) => { handleFilterByName(e, filterName) }}
                    handleUploadClick={handleUploadClick}
                    handleMultipleSiteUploadClick={handleMultipleSiteUploadClick}
                />
                <TableContainer className={classes.container} >
                    <Table stickyHeader
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            showCustomer={mspClientId === regClientId && mspStatus === 1}
                            numSelected={selected.length}
                            rowCount={filteredRows.length}
                            tagDataKeys={tagDataKeys} 
                        />
                        <TableBody>
                            {filteredRows?.map((row, index) => {
                                // const tagDataEntries = Object.entries(row.tag_data);
                                const ips = row.device_ip;
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row?.location_name}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        {mspClientId === regClientId && mspStatus === 1 ? (<TableCell align="left">{row.cust_name}</TableCell>) : null}
                                        <TableCell align="left">{row.location_name}</TableCell>
                                        <TableCell align="left">
                                            {ips.map((ip, ipIndex) => (
                                                <Chip label={ip} style={{ marginRight: "5px" }} />
                                            ))}

                                        </TableCell>
                                        <TableCell align="center">{formatHour(row.business_hr_start).replace('hrs', '')}-{formatHour(row.business_hr_end)}</TableCell>
                                        <TableCell align="center">{row.city}</TableCell>
                                        
                                        {tagDataKeys.map(key => (
                                    <TableCell key={key}>
                                        {row.tag_data[key] || ''} {/* Handle dynamic data */}
                                    </TableCell>
                                ))}
                                        {row.criticality === '0' && <TableCell align="center">{"Low"}</TableCell>}
                                        {row.criticality === '1' && <TableCell align="center">{"Medium"}</TableCell>}
                                        {row.criticality === '2' && <TableCell align="center">{"High"}</TableCell>}
                                        {row.criticality === '-1' && <TableCell align="center">{"Not Available"}</TableCell>}

                                        {row.status === '0' && <TableCell align="center">{"Paused"}</TableCell>}
                                        {row.status === '1' && <TableCell align="center">{"Running"}</TableCell>}
                                        <TableCell> {row.util_alert_status === 1 ? 'Enabled' : 'Disabled'}</TableCell>

                                        <TableCell align="left">{row.remarks}</TableCell>
                                        <TableCell align="center">{row.isp_cnt}</TableCell>
                                        <TableCell align="center">
                                            <Grid container direction="row" >
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton>
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnBusinessHourClick(e, row) }}><CalendarMonthIcon /></IconButton>
                                                </Grid>
                                                {/* utilization */}
                                                {/* <Grid item xs={4} sm={4} md={4} lg={4}> */}
                                                {/* {row.util_alert_status === 0 && <IconButton variant="outlined" value={row.location_id} color="error" onClick={() => { handleUtilizationAlertDialog(row.location_id, row.location_name, 1) }}><NotificationsOffIcon /></IconButton>} */}
                                                {/* {row.util_alert_status === 1 && <IconButton variant="outlined" value={row.location_id} color="primary" onClick={() => { handleUtilizationAlertDialog(row.location_id, row.location_name, 0) }}><NotificationsActiveIcon /></IconButton>} */}
                                                {<IconButton variant="outlined" value={row.location_id} color="primary" onClick={() => { handleAlertNotifyDailog(row.location_id, row.location_name, row.status, row.util_alert_status) }}><EditNotificationsIcon /></IconButton>}
                                                {/* </Grid> */}

                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                    {!isEngineer && <IconButton variant="outlined" color="error" onClick={(e) => { handleDelete(e, row) }}><DeleteIcon /></IconButton>}
                                                </Grid>
                                                {/* {row.status === '1' && <IconButton variant="outlined" value={row.location_id} color="error" onClick={() => { handlePauseDialog(row.location_id, row.location_name, 1) }}><StopCircleIcon /></IconButton>} */}
                                                {/* {row.status === '0' && <IconButton variant="outlined" value={row.location_id} color="primary" onClick={() => { handlePauseDialog(row.location_id, row.location_name, 0) }}><SmartDisplayIcon /></IconButton>} */}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {openEditDialog && <EditLocationDialog value={value} openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setUpdateStatus={setUpdateStatus} setSeverStatus={setSeverStatus} setOpen={setOpen} handleDataReLoad={handleDataReLoad} setUpdateMessage={setUpdateMessage} />}
                {openDeleteDialog && <DeleteLocationDialog value={value} openDeleteDialog={openDeleteDialog} setOpenDeletDialog={setOpenDeletDialog} setDeleteStatus={setDeleteStatus} setSeverStatus={setSeverStatus} setDeleteOpen={setDeleteOpen} handleDataReLoad={handleDataReLoad} />}
                {openAddEdDailog && <AddEdgeDeviceDialog value={value} openAddEdDailog={openAddEdDailog} setOpenAddEdDailog={setOpenAddEdDailog} setAddEdStatus={setAddEdStatus} setSeverStatus={setSeverStatus} setOpenAddEd={setOpenAddEd} handleDataReLoad={handleDataReLoad} />}
                {openDeleteEdDialog && <DeleteEdgeDeviceDialog value={value} openDeleteEdDialog={openDeleteEdDialog} setOpenDeleteEdDialog={setOpenDeleteEdDialog} setDeleteEdStatus={setDeleteEdStatus} setSeverStatus={setSeverStatus} setOpenDeleteEd={setOpenDeleteEd} handleDataReLoad={handleDataReLoad} />}
                {openUploadDialog && <EdgeDeviceUploadDialog value={value} openUploadDialog={openUploadDialog} setOpenUploadDialog={setOpenUploadDialog} setUploadEds={setUploadEds} />}

                {openMultipleSiteUploadDialog && <MultipleSiteUploadDialog value={value} setOpens={setOpenMulSite}
                    openMultipleSiteUploadDialog={openMultipleSiteUploadDialog} setOpenMultipleUploadDialog={setOpenMultipleUploadDialog} setSeverStatus={setSeverStatus} setMultipleSiteUploadStatus={setMultipleSiteUploadStatus} setMultipleSiteUploadMessage={setMultipleSiteUploadMessage} />}

                {openBusinesHourDialog && <AddBusinessHoursDialog value={value} openBusinesHourDialog={openBusinesHourDialog} setOpenBusinessHourDialog={setOpenBusinessHourDialog} setAddBusinessHrStatus={setAddBusinessHrStatus} setOpenBusnessHrAlert={setOpenBusnessHrAlert} setSeverStatus={setSeverStatus} />}

                {openAlertNotifiDialog && <NotificationAlertSiteDialog openAlertNotifiDialog={openAlertNotifiDialog} setOpenAlertNotifiDialog={setOpenAlertNotifiDialog} setAlertNotifyStatus={setAlertNotifyStatus} setAlertNotifyMessage={setAlertNotifyMessage} alertNotifyLocationId={alertNotifyLocationId} alertNotifyLocationName={alertNotifyLocationName} alertNotifyPauseStatus={alertNotifyPauseStatus} alertNotifyUtilAlertStatus={alertNotifyUtilAlertStatus} />}

                {openPauseDialog && <PauseLocationDailog pauseValue={pauseValue} pauseLocationName={pauseLocationName} openPauseDialog={openPauseDialog} handlePauseLocation={handlePauseLocation} />}

                {UtilizationAlertDialog && <UtilizationAlertDailog UtilizationAlertValue={UtilizationAlertValue}
                    UtilizationAlertSiteName={utilizationLocationName}
                    UtilizationAlertDialog={UtilizationAlertDialog} handleUtilizationAlertLink={handleUtilizationAlertLink} />}
                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}


                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} >
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                        {updateStatus === 1 && <strong>{updateMessage}</strong>}
                        {updateStatus === -1 && <strong>Error:{updateMessage}</strong>}
                    </Alert>
                </Snackbar>

                <Snackbar open={openMulSite} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
                        {multipleSiteUploadStatus === 1 && <strong>{multipleSiteUploadMessage}</strong>}
                        {multipleSiteUploadStatus === -1 && <strong>Error:{multipleSiteUploadMessage}</strong>}
                    </Alert>
                </Snackbar>


                <Snackbar open={deleteOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
                        {deleteStatus === 1 && <strong>Location Deleted Successfully</strong>}
                        {deleteStatus === -1 && <strong>Error in Deleting Location</strong>}
                    </Alert>
                </Snackbar>

                <Snackbar open={openAddEd} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
                        {addEdStatus === 1 && <strong>Edge Device Added Successfully</strong>}
                        {addEdStatus === -1 && <strong>Error in Adding Edge Device Location</strong>}
                        {addEdStatus === 2 && <strong>Edge Device already added</strong>}
                    </Alert>
                </Snackbar>

                <Snackbar open={openDeleteEd} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
                        {deleteEdStatus === 1 && <strong>Edge Device Deleted Successfully</strong>}
                        {deleteEdStatus === -1 && <strong>Error Edge Device</strong>}
                    </Alert>
                </Snackbar>

                <Snackbar open={uploadEds} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                        <strong>Check Audit Logs</strong>
                    </Alert>
                </Snackbar>

                <Snackbar open={openBusinessHrAlert} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
                        {addBusinessHrStatus === 1 && <strong> Business Hour Added Successfully </strong>}
                        {addBusinessHrStatus === -1 && <strong>Error in adding Business Hour</strong>}
                    </Alert>
                </Snackbar>

                <Snackbar open={openPauseSnack} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
                        {pauseValue === 1 && <Typography>Location Paused Successfully.</Typography>}
                        {pauseValue === 0 && <Typography>Location Resumed Successfully.</Typography>}
                    </Alert>
                </Snackbar>
                <Snackbar open={UtilizationOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'set alerts for link'}>
                    <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                        {UtilizationAlertValue === 1 && <Typography>Alerts enabled for the site</Typography>}
                        {UtilizationAlertValue === 0 && <Typography>Alerts disabled for the site</Typography>}
                    </Alert>
                </Snackbar>
                {alertNotifyStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                        {alertNotifyMessage}
                    </Alert>
                </Snackbar>}

                {alertNotifyStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                        Error: {alertNotifyMessage}
                    </Alert>
                </Snackbar>}

            </Paper>
        </Box>
    );
}