import React, { useState, useEffect, useMemo } from 'react';
// material
import {
  Link,
  Breadcrumbs,
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Alert,
  Paper,
  FormHelperText,
  Typography,
  Divider,
  Dialog,
  FormLabel,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { createFilterOptions } from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useParams, Routes, Route, useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import { ISPLocationsDataAPICall } from "./SitewiseRestAPICalls"
import { UpdateSingleISPInfo, GetSingleISPInfo } from "./ISPAddingAPICall"
import GetVendorDetailsApiCall from './ApiCalls/GetVendorDetailsApiCall';
// import linkType from "../Assets/LinkTypes";
import { useGlobalState } from '../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../components/WarningComponents/SelectCustomerWarningDialog';

const filter = createFilterOptions();

const linkType = ['ILL', 'Broadband', 'MPLS', 'P2P', 'DBB'];

export default function EditSingleISP() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();
  const { buName, setBuName } = useGlobalState();

  const { id } = useParams()
  const [ispLocations, setIspLocations] = useState([{}]);
  const [value, setValue] = useState(null);
  const [ispWanId, setIspWanId] = useState(id);
  const [circuitId, setCircuitId] = useState("");
  const [vendorId, setVendorId] = useState(-1);
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [defaultGateway, setDefaultGateway] = useState('');
  const [publicIp, setPublicIp] = useState("");
  const [publicIpTermination, setPublicIpTermination] = useState('');
  const [internalIp, setInternalIp] = useState("");
  const [promiseUptime, setPromisedUptime] = useState("");
  const [resolutionTime, setResolutionTime] = useState("");
  const [updateStatus, setUpdateStatus] = useState(0);
  const [link, setLink] = useState("");
  const [provisionedSpeed, setProvisionedSpeed] = useState('');
  const [price, setPrice] = useState('');
  const [city, setCity] = useState("");
  const [cityStatus, setCityStatus] = useState(true);
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [siteName, setSiteName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [warning, setWarning] = useState('');
  const [critical, setCritical] = useState('');

  const [vendors, setVendors] = useState([]);
  const [selectedVendorIndex, setSelectedVendorIndex] = useState(-2);
  const [selectedVendorId, setSelectedVendorId] = useState(-1);
  const [vendorDisable, setVendorDisable] = useState(false);

  const [editMessage, setEditMessage] = useState("");

  const [internalIpLabelShrink, setInternalIpLabelShrink] = useState(false);
  const [circuitIdHelperText, setCircuitIdHelperText] = useState("");
  const [ceIPHelperText, setCeIPHelperText] = useState('');
  const [peIPHelperText, setPeIPHelperText] = useState('');
  const [accountIdHelperText, setAccountIdHelperText] = useState('');
  const [publicIpHelperText, setPublicIpHelperText] = useState('');
  const [publicIpTerminationHelperText, setPublicIpTerminationHelperText] = useState('');
  const [DefaultGatewayHelperText, setDefaultGatewayHelperText] = useState("");
  const [wanPrivateIpHelperText, setWanPrivateIpHelperText] = useState('');
  const [provisionedSpeedHelperText, setProvisionedSpeedHelperText] = useState("");
  const [cityHelperText, setCityHelperText] = useState("");
  const [stateHelperText, setStateHelperText] = useState("");
  const [countryHelperText, setCountryHelperText] = useState('');
  const [siteNameHelperText, setSiteNameHelperText] = useState('');
  const [priceHelperText, setPriceHelperText] = useState("");
  const [managerNameHelperText, setManagerNameHelperText] = useState("");
  const [managerEmailHelperText, setManagerEmailHelperText] = useState("");
  const [vendorNameHelperText, setVendorNameHelperText] = useState("");
  const [vendorEmailHelperText, setVendorEmailHelperText] = useState("");
  const [promiseUptimeHelperText, setPromiseUptimeHelperText] = useState('');
  const [resolutionTimeHelperText, setResolutionTimeHelperText] = useState("");
  const [linkHelperText, setLinkHelperText] = useState("");
  const [vendorHelperText, setVendorHelperText] = useState("");
  const [siteHelperText, setSiteHelperText] = useState("");
  const [renewalHelperText, setRenewalHelperText] = useState("");
  const [warningHelperText, setWarningHelperText] = useState("");
  const [criticalHelperText, setCriticalHelperText] = useState("");
  const [thresholdWarning, setThresholdWarning] = useState(null);
  const [thresholdCritical, setThresholdCritical] = useState(null);
  const [error, setError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [publicIpError, setPublicIpError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const [Renewalerror, setRenewalError] = useState(false);

  const [locationData, setLocationdata] = useState(null);
  const [locationId, setLocationId] = useState(-1);
  const [firstLocationName, setFirstLocationName] = useState("");

  const [siteUtilWarning, setSiteUtilWarning] = useState(0)
  const [siteUtilCritical, setSiteUtilCritical] = useState(0)

  const navigate = useNavigate();

  // const errorRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const provisionedSpeedRegex = /^\d+$/;
  const cityRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
  const priceRegex = /^\d+$/;
  const vendorNameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
  const managernameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
  const locationRegex = /^[a-zA-Z][a-zA-Z0-9\s,_.-]*[a-zA-Z\d]$/;
  const placeRegex = /^[a-zA-Z0-9\s]+$/;
  const circuitIdRegex = /^[^\s].*$/;
  const IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const mailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
  const uptimeRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
  const hourRegex = /^([01]?[0-9]|2[0-4])$/;
  const numericPattern = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;

  const validateNumber = (value) => numericPattern.test(value);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const _ISPLocationsDataAPICall = async () => {
    await ISPLocationsDataAPICall(setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState,
      // setWarning, setCritical
      (warning) => setThresholdWarning(warning),  
      (critical) => setThresholdCritical(critical) 
    );
  }

  const _GetSingleISPInfo = async () => {
    await GetSingleISPInfo(id, setIspWanId, setCircuitId, setVendorName, setVendorEmail, setDefaultGateway, setPublicIp, setInternalIp, setPromisedUptime, setResolutionTime, setLink, setProvisionedSpeed, setCity, setState, setCountry, setManagerName, setManagerEmail, setPrice, setVendorId, setValue, setSiteName, setRenewalDate, setWarning, setCritical)
  }

  const _GetVendorDetailsApiCall = async () => {
    await GetVendorDetailsApiCall(setVendors);

  }

  useEffect(() => {
    _GetVendorDetailsApiCall();
    _ISPLocationsDataAPICall();
    _GetSingleISPInfo();
    setSelectedVendorIndex(vendors.findIndex(obj => obj.vendor_id === vendorId));
    setVendorDisable(selectedVendorIndex < 0);
  }, [vendorId, globalState]);


  useEffect(() => {
    if (link === 'ILL' || link === 'Broadband') {
      if (publicIp === internalIp) {
        setPublicIpTermination('On Edge Box');
      } else {
        setPublicIpTermination('On ISP Router');
      }
    }
  }, [link, publicIp, internalIp]);

  useEffect(() => {
    if (selectedVendorIndex === -1 && vendorId === -1) {
      // When both selectedVendorIndex and vendorId are -1, find the new vendor by name
      const newVendor = vendors.find(vendor => vendor.vendor_name === vendorName);
      if (newVendor) {
        setSelectedVendorIndex(vendors.indexOf(newVendor));
      }
    } else if (vendorId !== -1) {
      // When vendorId is not -1, ensure the vendor with this ID is selected
      const existingVendor = vendors.find(vendor => vendor.vendor_id === vendorId);
      if (existingVendor) {
        setSelectedVendorIndex(vendors.indexOf(existingVendor));
      }
    }
  }, [selectedVendorIndex, vendorId, vendors, vendorName]);


  const onWarningClose = () => {
    navigate('/dashboard/view-isp');
  }

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const buStatus = parseInt(localStorage.getItem("bu_status"), 10);
  const [confirmOpen, setConfirmOpen] = useState(false);


  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    _ISPLocationsDataAPICall();
  }, [globalState, buState])

  const handleOnChange = (e) => {
    switch (e.target.id) {

      case "link-type-id":
        if (link === "" || link === null) {
          setLinkError(true)
          setLinkHelperText("Select Link Type")
        } else {
          setLinkHelperText("")
          setLinkError(false)
        }
        setLink(e.target.value)
        break;

      case "circuitid":
        if (!e.target.value.match(circuitIdRegex)) {
          setCircuitIdHelperText("Enter Valid Circuit ID")
        }
        else {
          setCircuitIdHelperText("");
        }
        setCircuitId(e.target.value);
        break;

      case "publicip":
        if (!e.target.value.match(IpRegex)) {
          setInternalIpLabelShrink(true);
          setPublicIpHelperText("Enter Valid IP Address");
        } else {
          setPublicIpHelperText("");
          setWanPrivateIpHelperText("");
        }
        setPublicIp(e.target.value);
        if ((link === 'ILL' || link === 'Broadband') && publicIpTermination === 'On Edge Box') {
          setInternalIp(e.target.value);
        }
        break;

      case "wanPrivateIp":
        if (!e.target.value.match(IpRegex)) {
          setInternalIpLabelShrink(true)
          setWanPrivateIpHelperText("Enter Valid WAN Private IP Address");
        } else {
          setWanPrivateIpHelperText("");
        }
        setInternalIp(e.target.value);
        break;

      case "ce-ip":
        if (!e.target.value.match(IpRegex)) {
          setInternalIpLabelShrink(true);
          setCeIPHelperText("Enter valid CE-IP");
        } else {
          setCeIPHelperText("");
          setWanPrivateIpHelperText("");
        }
        setPublicIp(e.target.value);
        if (link === 'MPLS' || link === 'P2P') {
          setInternalIp(e.target.value);
        }
        break;

      case "pe-ip":
        if (!e.target.value.match(IpRegex)) {
          setPeIPHelperText("Enter valid PE-IP");
        } else {
          setPeIPHelperText("");
        }
        setDefaultGateway(e.target.value);
        break;

      case "accountid":
        if (!e.target.value.match(circuitIdRegex)) {
          setAccountIdHelperText("Enter Valid Account ID")
        }
        else {
          setAccountIdHelperText("");
        }
        setCircuitId(e.target.value);
        break;

      case "defaultgateway":
        if (!e.target.value.match(IpRegex)) {
          setDefaultGatewayHelperText("Enter valid Gateway");
        } else {
          setDefaultGatewayHelperText("");
        }
        setDefaultGateway(e.target.value);
        break;

      case "provisioned_speed":
        if (!e.target.value.match(provisionedSpeedRegex)) {
          setProvisionedSpeedHelperText("Enter Valid Provisioned Speed");
        } else {
          setProvisionedSpeedHelperText("");
        }
        setProvisionedSpeed(e.target.value);
        break;

      case "price":
        if (!e.target.value.match(priceRegex)) {
          setPriceHelperText("Enter valid Price");
        } else {
          setPriceHelperText("");
        }
        setPrice(e.target.value);
        break;

      case "location":
        if (value === null || !value.location) {
          setSiteHelperText("Select Location");
        } else {
          setSiteHelperText("");
        }
        setValue(e.target.value);
        break;

      case "site_name":
        if (!e.target.value.match(locationRegex)) {
          setSiteNameHelperText("Site name Should be Valid");
        } else {
          setSiteNameHelperText("");

        }
        setSiteName(e.target.value);
        break;

      case "city":
        if (!e.target.value.match(cityRegex)) {
          setCityHelperText("Enter valid City");
        } else {
          setCityHelperText("");
        }
        setCity(e.target.value);
        break;

      case "state":
        if (!e.target.value.match(placeRegex)) {
          setStateHelperText("State Should be Valid");
        } else {
          setStateHelperText("");
        }
        setState(e.target.value);
        break;

      case "country":
        if (!e.target.value.match(placeRegex)) {
          setCountryHelperText("Country Should be Valid");
        } else {
          setCountryHelperText("");
        }
        setCountry(e.target.value);
        break;

      case "manager_name":
        if (!e.target.value.match(managernameRegex)) {
          setManagerNameHelperText("Manager Name Should be Valid");
        }
        else {
          setManagerNameHelperText("");
        }
        setManagerName(e.target.value);
        break;

      case "manager_email":
        if (!e.target.value.match(mailRegex)) {
          setManagerEmailHelperText("Manager Email Should be Valid");
        }
        else {
          setManagerEmailHelperText("");
        }
        setManagerEmail(e.target.value);
        break;

      case "vendorname":
        if (!e.target.value.match(vendorNameRegex)) {
          setVendorNameHelperText("Enter Valid Vendor Name");
        } else {
          setVendorNameHelperText("");
        }
        setVendorName(e.target.value);
        break;

      case "vendoremail":
        if (!e.target.value.match(mailRegex)) {
          setVendorEmailHelperText("Enter valid Mail ID");
        } else {
          setVendorEmailHelperText("");
        }
        setVendorEmail(e.target.value);
        break;

      case "promised_uptime":
        if (!e.target.value.match(uptimeRegex)) {
          setPromiseUptimeHelperText("Enter Value only between 0-100");
        } else {
          setPromiseUptimeHelperText("");
        }
        setPromisedUptime(e.target.value);
        break;

      case "resolution_time":
        if (!e.target.value.match(hourRegex)) {
          setResolutionTimeHelperText("Enter Value Only between 0-24");
        } else {
          setResolutionTimeHelperText("");
        }
        setResolutionTime(e.target.value);
        break;

      default: break;
    }
  }


  const handleOnSubmit = async () => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
    }

    if (!link || link.trim() === "") {
      setLinkError(true);
      setLinkHelperText("Select Link Type");
      return;
    }

    if (link === 'ILL' && (!circuitId || !circuitId.match(circuitIdRegex))) {
      setCircuitIdHelperText("Enter Valid Circuit ID");
      return;
    }

    if ((link === 'Broadband' || link === 'DBB') && (!circuitId || !circuitId.match(circuitIdRegex))) {
      setAccountIdHelperText("Enter Valid Account ID");
      return;
    }

    if (link === 'ILL' || link === 'Broadband' || link === 'DBB') {
      if (!publicIpTermination) {
        setPublicIpTerminationHelperText('Please Select an option');
        setPublicIpError(true);
        return;
      }

      if (publicIpTermination === 'On Edge Box' && (!publicIp || !publicIp.match(IpRegex))) {
        setPublicIpHelperText("Enter Valid IP Address");
        return;
      }

      if (publicIpTermination === 'On Edge Box' && (!defaultGateway || !defaultGateway.match(IpRegex))) {
        setDefaultGatewayHelperText("Enter valid Gateway");
        return;
      }

      if (publicIpTermination === 'On ISP Router') {
        if (!publicIp || !publicIp.match(IpRegex)) {
          setPublicIpHelperText("Enter Valid IP Address");
          return;
        }

        if (!defaultGateway || !defaultGateway.match(IpRegex)) {
          setDefaultGatewayHelperText("Enter valid Gateway");
          return;
        }

        if (!internalIp || !internalIp.match(IpRegex)) {
          setWanPrivateIpHelperText("Enter Valid WAN Private IP Address");
          return;
        }
      }
    }

    if ((link === 'MPLS' || link === 'P2P') && (!circuitId || !circuitId.match(circuitIdRegex))) {
      setCircuitIdHelperText("Enter Valid Circuit ID");
      return;
    }

    if ((link === 'MPLS' || link === 'P2P') && (!publicIp || !publicIp.match(IpRegex))) {
      setCeIPHelperText("Enter valid CE-IP");
      return;
    }

    if ((link === 'MPLS' || link === 'P2P') && (!defaultGateway || !defaultGateway.match(IpRegex))) {
      setPeIPHelperText("Enter valid PE-IP");
      return;
    }

    if (!String(provisionedSpeed).match(provisionedSpeedRegex)) {
      setProvisionedSpeedHelperText("Enter Valid Provisioned Speed");
      return;
    }

    // if (!String(price).match(priceRegex)) {
    //   setPriceHelperText("Enter Valid Value");
    //   return;
    // }

    // if (renewalDate === undefined || renewalDate === null || renewalDate === "Invalid Date") {
    //   setRenewalError(true);
    //   setRenewalHelperText("Select a Date");
    //   return;
    // }

    if (!value || !value.location) {
      setSiteHelperText("Select Location");
      return;
    }

    if (value.location === 'New Site') {
      if (!siteName.match(locationRegex)) {
        setSiteNameHelperText("Enter valid Site name");
        return;
      }

      if (!city.match(cityRegex)) {
        setCityHelperText("Enter valid City");
        return;
      }

      if (!state.match(placeRegex)) {
        setStateHelperText("State Should be Valid");
        return;
      }

      if (!country.match(placeRegex)) {
        setCountryHelperText("Country Should be Valid");
        return;
      }
    }

    // if (!managerName.match(managernameRegex)) {
    //   setManagerNameHelperText("Manager Name Should be Valid");
    //   return;
    // }

    // if (!managerEmail.match(mailRegex)) {
    //   setManagerEmailHelperText("Manager Email Should be Valid");
    //   return;
    // }
    if (!validateNumber(warning)) {
      setWarningHelperText("Warning Threshold must be a valid number");
      return;
    }


    if (!validateNumber(critical)) {
      setCriticalHelperText("Critical Threshold must be a valid number");
      return;
    }
    if (selectedVendorIndex === undefined) {
      setError(true);
      setVendorHelperText("Select Vendor");
      return;
    }

    if (selectedVendorIndex === -2) {
      setUpdateStatus(-2);
      setAlertOpen(true);
      return;
    }

    // if (selectedVendorIndex !== -1) {
    //   if (vendorId !== -1 && selectedVendorId !== -1) {
    //     await UpdateSingleISPInfo(ispWanId, publicIp, internalIp, circuitId, value.location_id, siteName, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, state, country, managerName, managerEmail, price, renewalDate, warning, critical, setUpdateStatus, setEditMessage);
    //     setAlertOpen(true);
    //   }
    //   if (vendorId !== -1 && selectedVendorId === -1) {
    //     await UpdateSingleISPInfo(ispWanId, publicIp, internalIp, circuitId, value.location_id, siteName, vendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, state, country, managerName, managerEmail, price, renewalDate, warning, critical, setUpdateStatus, setEditMessage);
    //     setAlertOpen(true);

    //   }
    //   if (vendorId === -1 && selectedVendorId !== -1) {
    //     await UpdateSingleISPInfo(ispWanId, publicIp, internalIp, circuitId, value.location_id, siteName, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, state, country, managerName, managerEmail, price, renewalDate, warning, critical, setUpdateStatus, setEditMessage);
    //     setAlertOpen(true);

    //   }
    // }

    if (!vendorName.trim() || !vendorName.match(vendorNameRegex)) {
      setVendorNameHelperText("Enter Valid Vendor name");
      return;
    }

    if (!vendorEmail.match(mailRegex)) {
      setVendorEmailHelperText("Enter Valid Mail Id");
      return;
    }

    if (!String(promiseUptime).match(uptimeRegex)) {
      setPromiseUptimeHelperText("Enter Value only between 0-100");
      return;
    }

    if (!String(resolutionTime).match(hourRegex)) {
      setResolutionTimeHelperText("Enter Value Only between 0-24");
      return;
    }

    if (buStatus === 1 && buState !== -1) {
      setConfirmOpen(true);

    } else {
      await UpdateSingleISPInfo(ispWanId, publicIp, internalIp, circuitId, value.location_id, siteName, selectedVendorIndex, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, state, country, managerName, managerEmail, price, renewalDate, warning, critical, setUpdateStatus, setEditMessage);
      setAlertOpen(true);
    }
  }


  const handleConfirm = () => {
    UpdateSingleISPInfo(ispWanId, publicIp, internalIp, circuitId, value.location_id, siteName, selectedVendorIndex, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, state, country, managerName, managerEmail, price, renewalDate, warning, critical, setUpdateStatus, setEditMessage);
    setConfirmOpen(false);
  }

  const handleCancel = () => {
    setConfirmOpen(false);
  }

  // Auto-close alert after 6 seconds when data changes
  useEffect(() => {
    if (updateStatus !== 0) {
      setAlertOpen(true); // Set alert open when data changes

      const timer = setTimeout(() => {
        handleAlertClose()
      }, 6000);
      // Clean up the timer when the component is unmounted or data changes
      return () => clearTimeout(timer);
    }
  }, [updateStatus]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleOnCancel = () => {
    navigate('/dashboard/view-isp');
  }

  const handleLinkTypeChange = (e) => {
    const newValue = e.target.value;
    setLink(newValue);
    if (newValue === "" || newValue === null) {
      setLinkError(true);
      setLinkHelperText("Select Link Type");
    } else {
      setLinkHelperText("");
      setLinkError(false);
    }
    // Update internal IP based on the new link type
    if (newValue === 'MPLS' || newValue === 'P2P') {
      setInternalIp(publicIp);
    } else if (newValue === 'ILL' || newValue === 'Broadband') {
      if (publicIpTermination === 'On Edge Box') {
        setInternalIp(publicIp);
      } else {
        setInternalIp(internalIp);
      }
    }
  };

  const handlePublicIpTerminationChange = (event) => {
    const value = event.target.value;
    setPublicIpTermination(value);
    if ((link === 'ILL' || link === 'Broadband') && value === 'On Edge Box') {
      setInternalIp(publicIp);
    } else if ((link === 'ILL' || link === 'Broadband') && value === 'On ISP Router') {
      setInternalIp(internalIp);
    } else if ((link === 'MPLS' || link === 'P2P') && value === 'On ISP Router') {
      setInternalIp(publicIp);
    }
  };

  const handleVendorChange = (e) => {
    setSelectedVendorIndex(e.target.value);
    if (e.target.value >= 0) {
      setSelectedVendorId(vendors[e.target.value].vendor_id);
      setVendorName(vendors[e.target.value].vendor_name);
      setVendorEmail(vendors[e.target.value].helpdesk_email);
      setResolutionTime(vendors[e.target.value].resolution_time);
      setPromisedUptime(vendors[e.target.value].promised_uptime);
      setVendorDisable(true);
      setVendorHelperText("")
      setVendorNameHelperText("")
      setVendorEmailHelperText("")
      setPromiseUptimeHelperText("")
      setResolutionTimeHelperText("")
      setError(false)
    }
    else {
      setSelectedVendorId(-1);
      setVendorDisable(false);
      setVendorName("");
      setVendorEmail("");
      setResolutionTime("");
      setPromisedUptime("");
    }

  }

  const getDisplayText = (value) => {
    switch (value) {
      case "Broadband":
        return "Static Broadband";
      case "DBB":
        return "Dynamic Broadband";
      default:
        return value;
    }
  };

  const getVendorDisplayValue = () => {
    if (selectedVendorIndex === -1) {
      return vendorName;
    } if (selectedVendorIndex !== -1 || selectedVendorId !== -1) {
      return selectedVendorIndex;
    }
    return '';
  };

  return (
    <Page title="Edit Single ISP">
      <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">

          <Link underline="hover" color="inherit" href="/dashboard/">
            Settings
          </Link>
          <Link underline="hover" color="inherit" href="/dashboard/add-single-isp">
            Edit Link
          </Link>
        </Breadcrumbs>
      </div>

      <Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Paper elevation={15}>
            <div style={{ padding: "50px" }}>
              <h3>Edit Link</h3>

              <br />
              <form>

                <DialogContent sx={{ py: 1, px: 2 }}>
                  <form style={{ paddingBottom: "30px" }}>
                    <Typography variant='h6' sx={{ paddingBottom: "10px" }}>
                      Link Details
                    </Typography>

                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                      <Grid item xs={6}>
                        <FormControl fullWidth error={linkError}>
                          <InputLabel required id="link-type-label">Link Type</InputLabel>
                          <Select
                            labelId="link-type-label"
                            id="link-type-id"
                            label="Link Type"
                            style={{ width: "400px" }}
                            value={link}
                            fullWidth
                            error={linkHelperText}
                            onChange={handleLinkTypeChange}
                          >

                            {linkType.map((value, index) => (
                              <MenuItem key={index} value={value}>{getDisplayText(value)}</MenuItem>
                            ))}

                          </Select>
                          {linkError && <Typography variant="caption" color="error">{linkHelperText}</Typography>}
                        </FormControl>
                      </Grid>

                      {link === 'ILL' && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              id="circuitid"
                              label="Circuit ID"
                              variant="outlined"
                              value={circuitId}
                              style={{ width: "400px" }}
                              helperText={circuitIdHelperText}
                              error={!!circuitIdHelperText}
                              onChange={handleOnChange}
                              required />
                          </Grid>
                        </>
                      )}

                      {(link === 'Broadband' || link === 'DBB') && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              id="accountid"
                              label="Account ID"
                              variant="outlined"
                              value={circuitId}
                              style={{ width: "400px" }}
                              helperText={accountIdHelperText}
                              error={!!accountIdHelperText}
                              onChange={handleOnChange}
                              required />
                          </Grid>
                        </>
                      )}

                      {(link === 'ILL' || link === 'Broadband' || link === 'DBB') && (
                        // {(link === 'ILL' || link === 'Broadband') && (
                        <>
                          <Grid item xs={6}>
                            <FormControl error={publicIpError}>
                              <FormLabel id="public-ip-termination">Public IP Termination</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="public-ip-termination"
                                name="public-ip-termination"
                                value={publicIpTermination}
                                onChange={handlePublicIpTerminationChange}
                              >
                                <FormControlLabel value="On Edge Box" control={<Radio />} label="On Edge Box" />
                                <FormControlLabel value="On ISP Router" control={<Radio />} label="On ISP Router" />
                              </RadioGroup>
                              <FormHelperText>{publicIpTerminationHelperText}</FormHelperText>

                            </FormControl>
                          </Grid>

                          {publicIpTermination === 'On Edge Box' && (
                            <>
                              <Grid item xs={6}>
                                <TextField
                                  label="Public IP/CE-IP"
                                  id="publicip"
                                  value={publicIp}
                                  helperText={publicIpHelperText}
                                  error={!!publicIpHelperText}
                                  inputProps={{
                                    pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$",
                                    title: "For example:192.158.1.1"
                                  }}
                                  variant="outlined"
                                  style={{ width: "400px" }}
                                  onChange={handleOnChange}
                                  required
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  required
                                  id="defaultgateway"
                                  label="Wan Gateway/PE-IP"
                                  inputProps={{
                                    pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$",
                                    title: "For example:192.158.1.1"
                                  }}
                                  fullWidth
                                  value={defaultGateway}
                                  variant="outlined"
                                  style={{ width: "400px" }}
                                  error={!!DefaultGatewayHelperText}
                                  helperText={DefaultGatewayHelperText}
                                  onChange={handleOnChange}
                                />
                              </Grid>
                            </>
                          )}

                          {publicIpTermination === 'On ISP Router' && (
                            <>
                              <Grid item xs={6}>
                                <TextField
                                  label="Public IP/CE-IP"
                                  id="publicip"
                                  value={publicIp}
                                  helperText={publicIpHelperText}
                                  error={!!publicIpHelperText}
                                  // inputProps={{
                                  //   pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$",
                                  //   title: "For example:192.158.1.1"
                                  // }}
                                  variant="outlined"
                                  style={{ width: "400px" }}
                                  onChange={handleOnChange}
                                  autoComplete="off"
                                  required
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  required
                                  id="defaultgateway"
                                  label="Wan Gateway/PE-IP"
                                  // inputProps={{
                                  //   pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$",
                                  //   title: "For example:192.158.1.1"
                                  // }}
                                  fullWidth
                                  value={defaultGateway}
                                  variant="outlined"
                                  style={{ width: "400px" }}
                                  error={DefaultGatewayHelperText !== ""}
                                  helperText={DefaultGatewayHelperText}
                                  onChange={handleOnChange}
                                />
                              </Grid>


                              <Grid item xs={6}>
                                <TextField label="WAN Private IP on Edge Box" id="wanPrivateIp" value={internalIp} helperText={wanPrivateIpHelperText} error={!!wanPrivateIpHelperText} inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
                              </Grid>
                            </>
                          )}
                        </>
                      )}

                      {(link === 'MPLS' || link === 'P2P') && (
                        <>
                          <Grid item xs={6}>
                            <TextField label="Circuit ID" id="circuitid" variant="outlined" value={circuitId} style={{ width: "400px" }} helperText={circuitIdHelperText} error={!!circuitIdHelperText} onChange={handleOnChange} required />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField label="CE-IP" id="ce-ip" variant="outlined" value={publicIp} style={{ width: "400px" }} helperText={ceIPHelperText} error={!!ceIPHelperText} onChange={handleOnChange} required />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField label="PE-IP" id="pe-ip" variant="outlined" value={defaultGateway} style={{ width: "400px" }} helperText={peIPHelperText} error={!!peIPHelperText} onChange={handleOnChange} required />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={6}>
                        <TextField id="provisioned_speed" label="Provisioned Speed (in Mbps)" fullWidth variant="outlined" value={provisionedSpeed} style={{ width: "400px" }} error={!!provisionedSpeedHelperText} helperText={provisionedSpeedHelperText} onChange={handleOnChange} required />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField id="price" label="Price" fullWidth variant="outlined" value={price} style={{ width: "400px" }} error={!!priceHelperText} helperText={priceHelperText} onChange={handleOnChange} />
                      </Grid>

                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} error={Renewalerror}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              label="Renewal Date"
                              value={renewalDate}
                              fullWidth
                              sx={{ width: "400px" }}
                              inputFormat="MM/DD/YYYY"
                              // error={Renewalerror}
                              onChange={(newValue) => {
                                setRenewalDate(newValue);
                                setRenewalError(false);
                              }}
                              renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} inputProps={{ readOnly: true }} error={Renewalerror} helperText={renewalHelperText} />}
                            />
                          </DemoContainer>
                          {Renewalerror && <Typography variant="caption" color="error">{renewalHelperText}</Typography>}
                        </LocalizationProvider>
                      </Grid>

                    </Grid>
                  </form>


                  <form style={{ paddingBottom: "30px" }}>
                    <Typography variant='h6' sx={{ paddingBottom: "10px" }}>
                      Site Details
                    </Typography>

                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                      <Grid item xs={6}>

                        <Autocomplete
                          value={value}
                          onChange={(event, newValue) => {
                            setCity("");
                            setState("");
                            setCountry("");
                            setManagerName("");
                            setManagerEmail("");
                            setWarning("");
                            setCritical("");

                            if (typeof newValue === 'string') {
                              setValue({
                                location_id: newValue.location_id,
                              });
                              setSiteName(newValue.location);
                              setCityStatus(false);
                            }
                            // else if (newValue && newValue.location === "New Site") {
                            else if (newValue) {
                              setValue(newValue);
                              setSiteName("");
                              setCity(newValue.city);
                              setCityStatus(true);
                              setWarning(newValue.util_warn_th || "80");
                              setCritical(newValue.util_critical_th || "100");
                            }
                            else {
                              setValue(newValue);
                              setSiteName(newValue.location);
                              setCity(newValue.city);
                              setCityStatus(true);
                              setWarning(newValue.util_warn_th || "80");
                              setCritical(newValue.util_critical_th || "100")
                            }
                            // Validate the "Site" field here
                            if (newValue === null || !newValue.location) {
                              setSiteHelperText("Select Location");
                            } else {
                              setSiteHelperText("");
                            }
                          }}

                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          // id="free-solo-with-text-demo"
                          // options={ispLocations}
                          //  options={[...ispLocations, { location: "New Site", location_id: -1 }]}
                          options={ispLocations}

                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.location || "No location assigned";
                          }}
                          renderOption={(props, option) => <li {...props}>{option.location || "No location assigned"}</li>}
                          sx={{ width: 250 }}
                          location
                          renderInput={(params) => (
                            <TextField {...params} label="Site" id="location" required style={{ width: "400px" }} helperText={siteHelperText} error={!!siteHelperText} onChange={handleOnChange} />
                          )}
                        />
                      </Grid>

                      {value && value.location === "New Site" ? (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              required
                              id="site_name"
                              label="Site Name"
                              fullWidth
                              value={siteName}
                              variant="outlined"
                              style={{ width: "400px" }}
                              error={siteNameHelperText}
                              helperText={siteNameHelperText}
                              onChange={handleOnChange}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              required
                              id="city"
                              label="City"
                              fullWidth
                              value={city}
                              variant="outlined"
                              // type="text"
                              style={{ width: "400px" }}
                              onChange={handleOnChange}
                              error={!!cityHelperText}
                              helperText={cityHelperText}
                              disabled={cityStatus}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              required
                              id="state"
                              label="State"
                              fullWidth
                              value={state}
                              variant="outlined"
                              style={{ width: "400px" }}
                              error={stateHelperText}
                              helperText={stateHelperText}
                              onChange={handleOnChange}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              required
                              id="country"
                              label="Country"
                              fullWidth
                              value={country}
                              variant="outlined"
                              style={{ width: "400px" }}
                              error={countryHelperText}
                              helperText={countryHelperText}
                              onChange={handleOnChange}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label="Manager Name"
                              id="manager_name"
                              helperText={managerNameHelperText}
                              error={managerNameHelperText}
                              value={managerName}
                              variant="outlined"
                              style={{ width: "400px" }}
                              onChange={handleOnChange}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label="Manager Email"
                              id="manager_email"
                              helperText={managerEmailHelperText}
                              error={!!managerEmailHelperText}
                              value={managerEmail}
                              variant="outlined"
                              style={{ width: "400px" }}
                              onChange={handleOnChange}
                            />
                          </Grid>

                        </>
                      ) :

                        (<Grid item xs={6}>
                          <TextField
                            required
                            id="city"
                            label="City"
                            fullWidth
                            value={city}
                            variant="outlined"
                            // type="text"
                            style={{ width: "400px" }}
                            onChange={handleOnChange}
                            error={!!cityHelperText}
                            helperText={cityHelperText}
                            disabled={cityStatus}
                          />
                        </Grid>)
                      }
                    </Grid>
                    <Typography variant='h6' sx={{ paddingBottom: "10px", paddingTop: "20px" }}>
                      Utilization Threshold
                    </Typography>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                      <Grid item xs={6}>
                        <TextField
                          id="warning-thresh"
                          label="Warning Threshold"
                          variant="outlined"
                          value={warning}
                          placeholder="Enter Warning Threshold(in %)"
                          onChange={(e) => {
                            // setWarningHelperText("");
                            // setWarning(e.target.value);
                            const value = e.target.value;
                            setWarning(value);
                            if (!validateNumber(value)) {
                              setWarningHelperText("Warning Threshold must be a valid number");
                            } else {
                              setWarningHelperText("");
                            }
                          }}
                          helperText={warningHelperText}
                          error={!!warningHelperText}
                          sx={{ width: "400px" }}
                          InputLabelProps={{ shrink: true }} // Ensures the label floats correctly
                          required
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="critical-thresh"
                          label="Critical Threshold"
                          variant="outlined"
                          value={critical}
                          placeholder="Enter Critical Threshold(in %)"
                          onChange={(e) => {
                            // setCriticalHelperText("");
                            // setCritical(e.target.value);
                            const value = e.target.value;
                            setCritical(value);
                            if (!validateNumber(value)) {
                              setCriticalHelperText("Critical Threshold must be a valid number");
                            } else {
                              setCriticalHelperText("");
                            }
                          }}
                          helperText={criticalHelperText}
                          error={!!criticalHelperText}
                          sx={{ width: "400px" }}
                          InputLabelProps={{ shrink: true }} // Ensures the label floats correctly
                          required
                        />
                      </Grid>

                    </Grid>

                  </form>

                  <form style={{ paddingBottom: "30px" }}>
                    <Typography variant='h6' sx={{ paddingBottom: "10px" }}>
                      Vendor Details
                    </Typography>

                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                      <Grid item xs={6}>
                        <FormControl fullWidth error={!!vendorHelperText}>
                          <InputLabel required id="Vendor-label">Vendor</InputLabel>
                          <Select
                            labelId="Vendor-label"
                            id="Vendor"
                            style={{ width: "400px" }}
                            label="Vendor"
                            value={selectedVendorIndex}
                            onChange={handleVendorChange}
                          >

                            {vendors.map((val, index) => (
                              <MenuItem value={index}>{val.vendor_name}</MenuItem>
                            ))}
                            <MenuItem value={-1}>New Vendor</MenuItem>
                          </Select>
                          <FormHelperText>{error ? vendorHelperText : ""}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField required id="vendorname" label="Vendor Name" fullWidth value={vendorName} variant="outlined" style={{ width: "400px" }} disabled={vendorDisable} error={!!vendorNameHelperText} helperText={vendorNameHelperText} onChange={handleOnChange} />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField required id="vendoremail" label="Vendor Support E-mail" fullWidth value={vendorEmail} variant="outlined" type={"email"} style={{ width: "400px" }} disabled={vendorDisable} error={!!vendorEmailHelperText} helperText={vendorEmailHelperText} onChange={handleOnChange} />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField required id="promised_uptime" label="Promised Uptime (in %)" fullWidth value={promiseUptime} InputProps={{ inputProps: { min: 0, max: 100 } }} variant="outlined" style={{ width: "400px" }} disabled={vendorDisable} error={!!promiseUptimeHelperText} helperText={promiseUptimeHelperText} onChange={handleOnChange} />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField required id="resolution_time" label="Resolution Time (in Hours)" fullWidth value={resolutionTime} variant="outlined" style={{ width: "400px" }} disabled={vendorDisable} error={!!resolutionTimeHelperText} helperText={resolutionTimeHelperText} onChange={handleOnChange} />
                      </Grid>

                    </Grid>
                  </form>

                </DialogContent>

              </form>
              <br /><br />
              <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <Button style={{ width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnSubmit}  >
                    Update
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button style={{ background: "red", width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnCancel}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              <br />

              {updateStatus === 1 && alertOpen && (<Alert severity="success" onClose={handleAlertClose}>{editMessage}</Alert>)}
              {updateStatus === 2 && alertOpen && (<Alert severity="warning" onClose={handleAlertClose}>{editMessage}</Alert>)}
              {updateStatus === -1 && alertOpen && (<Alert severity="error" onClose={handleAlertClose}>Error:{editMessage}</Alert>)}

            </div>
          </Paper>

        </Grid >
      </Grid >
      {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />
      }
      <Dialog
        open={confirmOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Warning !!!
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`Are you sure you want to edit the link for the business unit - ${buName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Yes
          </Button>
          <Button onClick={handleCancel} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Page >
  );

}
