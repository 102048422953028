import React, { useState, useEffect } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup
} from '@mui/material';

import UpdateLocationApi from './UpdateLocationApi';
import GetUserDetailsApiCall from '../../ZoneComponents/Components/GetUserDetailsApiCall';


export default function EditLocationDialog(props) {
  const { value, openEditDialog, setOpenEditDialog, setUpdateStatus, setSeverStatus, setOpen, setUpdateMessage } = props;
  const [locationName, setLocationName] = useState(value.location_name);
  const [businessHrStart, setBusinessHrStart] = useState(value.business_hr_start);
  const [businessHrEnd, setBusinessHrEnd] = useState(value.business_hr_end);
  const [city, setCity] = useState(value.city);
  const [state, setState] = useState(value.state);
  const [country, setCountry] = useState(value.country);
  const [remarks, setRemarks] = useState(value.remarks);

  const [users, setUsers] = useState([]);
  const [escMail, setEscMail] = useState(value.escalation_mail_id);

  const [criticality, setCriticality] = useState(value.criticality);
  const [criticalityHelperText, setCriticalityHelperText] = useState("");

  const [managerId, setManagerId] = useState(-1);
  const [managerName, setManagerName] = useState(value.manager_name);
  const [managerContact, setManagerContact] = useState(value.manager_contact);
  const [managerEmail, setManagerEmail] = useState(value.manager_email);
  const [disableManagerFields, setDisableManagerFields] = useState(false);

  const [esc1Name, setEsc1Name] = useState(value.esc_name_1);
  const [esc1Contact, setEsc1Contact] = useState(value.esc_ph_no_1);
  const [esc1Email, setEsc1Email] = useState(value.esc_mail_1);
  const [esc1Threshold, setEsc1Threshold] = useState(value.threshold_1);

  const [esc2Name, setEsc2Name] = useState(value.esc_name_2);
  const [esc2Contact, setEsc2Contact] = useState(value.esc_ph_no_2);
  const [esc2Email, setEsc2Email] = useState(value.esc_mail_2);
  const [esc2Threshold, setEsc2Threshold] = useState(value.threshold_2);

  const [esc3Name, setEsc3Name] = useState(value.esc_name_3);
  const [esc3Contact, setEsc3Contact] = useState(value.esc_ph_no_3);
  const [esc3Email, setEsc3Email] = useState(value.esc_mail_3);
  const [esc3Threshold, setEsc3Threshold] = useState(value.threshold_3);

  const [latency, setLatency] = useState(value.latency);
  const [jitter, setJitter] = useState(value.jitter);
  const [packetLoss, setPacketLoss] = useState(value.packet_loss);
  const [threshold, setThreshold] = useState(value.threshold);


  const [locationNameHelperText, setLocationNameHelperText] = useState("");
  const [cityHelperText, setCityHelperText] = useState("");
  const [stateHelperText, setStateHelperText] = useState("");
  const [countryHelperText, setCountryHelperText] = useState("");
  const [error, setError] = useState(false);

  const [businessHrStartHelperText, setBusinessHrStartHelperText] = useState();
  const [businessHrEndHelperText, setBusinessHrEndHelperText] = useState();
  const [latencyHelperText, setLatencyHelperText] = useState("");
  const [jitterHelperText, setJitterHelperText] = useState("");
  const [packetLossHelperText, setPacketLossHelperText] = useState("");
  const [thresholdHelperText, setThresholdHelperText] = useState("");
  const [ManagerNameHelperText, setManagerNameHelperText] = useState("");
  const [ManagerContactHelperText, setManagerContactHelperText] = useState("");
  const [ManagerEmailHelperText, setManagerEmailHelperText] = useState("");
  const [eSCName1HelperText, setESCName1HelperText] = useState("");
  const [eSCName2HelperText, setESCName2HelperText] = useState("");
  const [eSCName3HelperText, setESCName3HelperText] = useState("");

  const [eSContact1HelperText, setESContact1HelperText] = useState("");
  const [eSContact2HelperText, setESContact2HelperText] = useState("");
  const [eSContact3HelperText, setESContact3HelperText] = useState("");

  const [eSEmail1HelperText, setESEmail1HelperText] = useState("");
  const [eSEmail2HelperText, setESEmail2HelperText] = useState("");
  const [eSEmail3HelperText, setESEmail3HelperText] = useState("");

  const [eSThreshold1HelperText, setESThreshold1HelperText] = useState("");
  const [eSThreshold2HelperText, setESThreshold2HelperText] = useState("");
  const [eSThreshold3HelperText, setESThreshold3HelperText] = useState("");

  const [warning, setWarning] = useState(value.util_warn_th || "80");
  const [warningHelperText, setWarningHelperText] = useState("");
  const [critical, setCritical] = useState(value.util_critical_th || "100");
  const [criticalHelperText, setCriticalHelperText] = useState("");

  const [pauseStatus, setPauseStatus] = useState(value.status);

  const locationRegex = /^[a-zA-Z][a-zA-Z0-9\s,_.-]*[a-zA-Z\d]$/;
  const placeRegex = /^[a-zA-Z0-9\s]+$/;
  const businessHrRegex = /^(?:[0-9]|1[0-9]|2[0-4])$/;
  const latencyRegex = /^\d+(\.\d+)?$/;
  const packetLossRegex = /^(?:\d{1,2}(?:\.\d+)?|\d{1,2}|100(?:\.0+)?)$/;
  const thresholdRegex = /^[1-9]\d*$/;
  const managernameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
  const managercontactRegex = /^[0-9]{10}$/;
  const manageremailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const numericPattern = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;

  useEffect(() => {
    GetUserDetailsApiCall(setUsers);
  }, [])

  const _UpdateLocation = async () => {
    //   if (!locationName.match(locationRegex)) {
    //     setLocationNameHelperText("Location Should be Valid");
    //     return;
    // }

    // if (!businessHrStart.match(businessHrRegex)) {
    //     setBusinessHrStartHelperText("Provide hours between 0-24");
    //     return;
    // }

    // if (!businessHrEnd.match(businessHrRegex)) {
    //     setBusinessHrEndHelperText("Provide hours between 0-24");
    //     return;
    // }


    // if (!latency.match(latencyRegex)) {
    //     setBusinessHrEndHelperText("Enter Latency Correctly");
    //     return;
    // }

    // if (!jitter.match(latencyRegex)) {
    //     setJitterHelperText("Enter Jitter Correctly");
    //     return;
    // }

    // if (!packetLoss.match(packetLossRegex)) {
    //     setPacketLossHelperText("Enter Packet loss Correctly");
    //     return;
    // }

    // if (criticality === -1) {
    //     setError(true);
    //     setCriticalityHelperText("Select Criticality");
    //     return;
    // }
    await UpdateLocationApi(value.location_id, value.location_name, locationName, businessHrStart, businessHrEnd, city, state, country, remarks, managerId, managerName, managerContact, managerEmail, esc1Name, esc1Email, esc1Contact, esc1Threshold, esc2Name, esc2Email, esc2Contact, esc2Threshold, esc3Name, esc3Email, esc3Contact, esc3Threshold, latency, jitter, packetLoss, criticality, warning, critical, pauseStatus, setUpdateStatus, setSeverStatus, setUpdateMessage, setOpen);
    props.handleDataReLoad();

    // setError(false);
    // setCriticalityHelperText("");
  }
  const validateNumber = (value) => numericPattern.test(value);
  const handleOnUpdate = () => {
    if (!validateNumber(warning)) {
      setWarningHelperText("Warning Threshold must be a valid number");
      return;
    }


    if (!validateNumber(critical)) {
      setCriticalHelperText("Critical Threshold must be a valid number");
      return;
    }

    if (isMandatoryFieldUpdated) {
      _UpdateLocation();

      setOpenEditDialog(false);
    }



  }

  const isMandatoryFieldUpdated = (businessHrStart !== "") && (businessHrEnd !== "") && (latency !== "") && (jitter !== "")
    && (packetLoss !== "") && (criticality !== "") && (city !== "") && (state !== "") && (country !== "") && (locationName !== "") && (latency !== null) && (jitter !== null)
    && (packetLoss !== null);




  const onChangeTextField = (e) => {


    switch (e.target.id) {
      case "location_name": if (e.target.value === "" || !e.target.value.match(locationRegex)) {
        setLocationNameHelperText("Location Should be Valid");
      }
      else {
        setLocationNameHelperText("");
      }
        setLocationName(e.target.value);
        break;
      case "city":
        if (!e.target.value.match(placeRegex)) {
          setCityHelperText("City Should be Valid");

        }
        else {
          setCityHelperText("");

        }
        setCity(e.target.value);
        break;
      case "state":
        if (!e.target.value.match(placeRegex)) {
          setStateHelperText("State Should be Valid");
        }
        else {
          setStateHelperText("");

        }
        setState(e.target.value);
        break;
      case "country":
        if (!e.target.value.match(placeRegex)) {
          setCountryHelperText("Country Should be Valid");
        }
        else {
          setCountryHelperText("");

        }
        setCountry(e.target.value);
        break;

      case "business_hr_start":
        if (!e.target.value.match(businessHrRegex)) {
          setBusinessHrStartHelperText("Provide hours between 0-24");
        }
        else {
          setBusinessHrStartHelperText("");

        }
        setBusinessHrStart(e.target.value);
        break;

      // case "business_hr_end": 
      // if (!e.target.value.match(businessHrRegex)) {
      //   setBusinessHrEndHelperText("Provide hours between 0-24");
      // }
      // else {
      //   setBusinessHrEndHelperText("");

      // }
      //   setBusinessHrEnd(e.target.value);
      //   break;
      case "business_hr_end":
        if (!e.target.value.match(businessHrRegex)) {
          setBusinessHrEndHelperText("Provide hours between 0-24");
        } else {
          const startTime = parseInt(businessHrStart, 10);
          const endTime = parseInt(e.target.value, 10);

          if (endTime <= startTime) {
            setBusinessHrEndHelperText("End time must be greater than start time");
          } else {
            setBusinessHrEndHelperText("");
          }
        }
        setBusinessHrEnd(e.target.value);
        break;
      case "latency":
        if (!e.target.value.match(latencyRegex)) {
          setLatencyHelperText("Enter Latency Correctly");
        }
        else {
          setLatencyHelperText("");

        }
        setLatency(e.target.value);
        break;

      case "jitter":
        if (!e.target.value.match(latencyRegex)) {
          setJitterHelperText("Enter Jitter Correctly");
        }
        else {
          setJitterHelperText("");

        }
        setJitter(e.target.value);
        break;

      case "packet_loss":
        if (!e.target.value.match(packetLossRegex)) {
          setPacketLossHelperText("Enter Packet Loss Correctly");
        }
        else {
          setPacketLossHelperText("");

        }
        setPacketLoss(e.target.value);
        break;
      case "threshold":
        if (!e.target.value.match(thresholdRegex)) {
          setThresholdHelperText("Must be greater than Zero");
        }
        else {
          setThresholdHelperText("");

        }
        setThreshold(e.target.value);
        break;
      case "manager_name":
        if (!e.target.value.match(managernameRegex)) {
          setManagerNameHelperText("Manager Name Should be Valid");
        }
        else {
          setManagerNameHelperText("");

        }
        setManagerName(e.target.value);
        break;

      case "esc1_name":
        if (!e.target.value.match(managernameRegex)) {
          setESCName1HelperText("Escalation Name 1 Should be Valid");
        }
        else {
          setESCName1HelperText("");

        }
        setEsc1Name(e.target.value);
        break;

      case "esc2_name":
        if (!e.target.value.match(managernameRegex)) {
          setESCName2HelperText("Escalation Name 2 Should be Valid");
        }
        else {
          setESCName2HelperText("");

        }
        setEsc2Name(e.target.value);
        break;
      case "esc3_name":
        if (!e.target.value.match(managernameRegex)) {
          setESCName3HelperText("Escalation Name 3 Should be Valid");
        }
        else {
          setESCName3HelperText("");

        }
        setEsc3Name(e.target.value);
        break;

      case "manager_contact":
        if (!e.target.value.match(managercontactRegex)) {
          setManagerContactHelperText("Manager Contact Should be Valid");
        }
        else {
          setManagerContactHelperText("");

        }
        setManagerContact(e.target.value);
        break;
      case "esc1_contact":
        if (!e.target.value.match(managercontactRegex)) {
          setESContact1HelperText("Escalation Contact 1 Should be Valid");
        }
        else {
          setESContact1HelperText("");

        }
        setEsc1Contact(e.target.value);
        break;

      case "esc2_contact":
        if (!e.target.value.match(managercontactRegex)) {
          setESContact2HelperText("Escalation Contact 2 Should be Valid");
        }
        else {
          setESContact2HelperText("");

        }
        setEsc2Contact(e.target.value);
        break;

      case "esc3_contact":
        if (!e.target.value.match(managercontactRegex)) {
          setESContact3HelperText("Escalation Contact 3 Should be Valid");
        }
        else {
          setESContact3HelperText("");

        }
        setEsc3Contact(e.target.value);
        break;

      case "manager_email":
        if (!e.target.value.match(manageremailRegex)) {
          setManagerEmailHelperText("Manager Email Should be Valid");
        }
        else {
          setManagerEmailHelperText("");

        }
        setManagerEmail(e.target.value);
        break;
      case "esc1_email":
        if (!e.target.value.match(manageremailRegex)) {
          setESEmail1HelperText("Escalation Email 1 Should be Valid");
        }
        else {
          setESEmail1HelperText("");

        }
        setEsc1Email(e.target.value);
        break;

      case "esc2_email":
        if (!e.target.value.match(manageremailRegex)) {
          setESEmail2HelperText("Escalation Email 2 Should be Valid");
        }
        else {
          setESEmail2HelperText("");

        }
        setEsc2Email(e.target.value);
        break;

      case "esc3_email":
        if (!e.target.value.match(manageremailRegex)) {
          setESEmail3HelperText("Escalation Email 3 Should be Valid");
        }
        else {
          setESEmail3HelperText("");

        }
        setEsc3Email(e.target.value);
        break;
      case "esc1_threshold": if (!e.target.value.match(thresholdRegex)) {
        setESThreshold1HelperText("Must be greater than Zero");
      }
      else {
        setESThreshold1HelperText("");

      }
        setEsc1Threshold(e.target.value);
        break;

      case "esc2_threshold":
        if (!e.target.value.match(thresholdRegex)) {
          setESThreshold2HelperText("Must be greater than Zero");
        }
        else {
          setESThreshold2HelperText("");

        }
        setEsc2Threshold(e.target.value);
        break;

      case "esc3_threshold":
        if (!e.target.value.match(thresholdRegex)) {
          setESThreshold3HelperText("Must be greater than Zero");
        }
        else {
          setESThreshold3HelperText("");

        }
        setEsc3Threshold(e.target.value);
        break;

      default: break;
    }

  }


  const onChangeManager = (e) => {
    if (e.target.value !== -1) {
      setManagerId(e.target.value)
      setDisableManagerFields(true)
      const filteredUsers = users.filter((item) =>
        item.user_id.includes(e.target.value)
      );
      const user = filteredUsers.find(() => true);
      setManagerName(user.first_name);
      setManagerContact(user.phone);
      setManagerEmail(user.email)
    }
    else {
      setManagerId(-1)
      setDisableManagerFields(false)
      setManagerName("");
      setManagerContact("");
      setManagerEmail("")
    }
  }

  return (
    <>
      <Dialog open={openEditDialog} onClose={() => { setOpenEditDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Edit Site
          <IconButton onClick={() => { setOpenEditDialog(false) }} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 6, px: 3 }}>
          <form>
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Basic Information
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Site Name"
                  id="location_name"
                  value={locationName === null ? "" : locationName}
                  variant="outlined"
                  style={{ width: "400px" }}
                  helperText={locationNameHelperText}
                  error={!!locationNameHelperText}
                  onChange={onChangeTextField}
                  required />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Business Hour Start (0-24)"
                  id="business_hr_start"
                  value={businessHrStart === null ? "" : businessHrStart}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={businessHrStartHelperText}
                  error={!!businessHrStartHelperText}
                  required />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Business Hour End (0-24)"
                  id="business_hr_end"
                  value={businessHrEnd === null ? "" : businessHrEnd}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={businessHrEndHelperText}
                  error={!!businessHrEndHelperText}
                  required />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="City"
                  id="city"
                  value={city === null ? "" : city}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={cityHelperText}
                  error={!!cityHelperText}
                  required />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="State"
                  id="state"
                  value={state === null ? "" : state}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={stateHelperText}
                  error={!!stateHelperText}
                  required />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Country"
                  id="country"
                  value={country === null ? "" : country}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={countryHelperText}
                  error={!!countryHelperText}
                  required />
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Contact Details
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={disableManagerFields}
                  label="Manager Name"
                  id="manager_name"
                  value={managerName === null ? "" : managerName}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={ManagerNameHelperText}
                  error={!!ManagerNameHelperText} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={disableManagerFields}
                  label="Manager Contact"
                  id="manager_contact"
                  value={managerContact === null ? "" : managerContact}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={ManagerContactHelperText}
                  error={!!ManagerContactHelperText} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={disableManagerFields}
                  label="Manager Email"
                  id="manager_email"
                  value={managerEmail === null ? "" : managerEmail}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={onChangeTextField}
                  helperText={ManagerEmailHelperText}
                  error={!!ManagerEmailHelperText} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Remarks (Max 100 Characters)"
                  id="remarks"
                  value={remarks === null ? "" : remarks}
                  variant="outlined"
                  style={{ width: "400px" }}
                  onChange={(e) => { setRemarks(e.target.value) }} />
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Utilization Threshold
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="Waring-Thresh"
                  label="Warning Threshold"
                  variant="outlined"
                  value={warning}
                  placeholder="Enter Warning Threshold(in %)"
                  onChange={(e) => {
                    // setWarningHelperText("");
                    // setWarning(e.target.value)
                    const value = e.target.value;
                    setWarning(value);

                    if (!validateNumber(value)) {
                      setWarningHelperText("Warning Threshold must be a valid number");
                    } else {
                      setWarningHelperText("");
                    }
                  }
                  }
                  fullWidth
                  style={{ width: "400px" }}
                  helperText={warningHelperText}
                  error={!!warningHelperText}
                  required
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="Critical-Thresh"
                  label="Critical Threshold"
                  variant="outlined"
                  value={critical}
                  placeholder="Enter Critical Threshold(in %)"
                  onChange={(e) => {
                    // setCriticalHelperText("");
                    // setCritical(e.target.value)
                    const value = e.target.value;
                    setCritical(value);

                    if (!validateNumber(value)) {
                      setCriticalHelperText("Critical Threshold must be a valid number");
                    } else {
                      setCriticalHelperText("");
                    }
                  }
                  }
                  fullWidth
                  style={{ width: "400px" }}
                  helperText={criticalHelperText}
                  error={!!criticalHelperText}
                  required
                />
              </Grid>



            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Level-1 Escalation Details (Optional)
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField label="Name" id="esc1_name" value={esc1Name} variant="outlined" helperText={eSCName1HelperText}
                  error={eSCName1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Email" id="esc1_email" value={esc1Email} variant="outlined" helperText={eSEmail1HelperText}
                  error={!!eSEmail1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Contact" id="esc1_contact" value={esc1Contact} variant="outlined" helperText={eSContact1HelperText}
                  error={eSContact1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Threshold(In Minutes)" id="esc1_threshold" value={esc1Threshold} variant="outlined" helperText={eSThreshold1HelperText} error={eSThreshold1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>
            </Grid>

            <Divider sx={{ paddingTop: "20px" }} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Level-2 Escalation Details (Optional)
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField label="Name" id="esc2_name" value={esc2Name} variant="outlined" helperText={eSCName2HelperText}
                  error={eSCName2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Email" id="esc2_email" value={esc2Email} variant="outlined" helperText={eSEmail2HelperText}
                  error={!!eSEmail2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Contact" id="esc2_contact" value={esc2Contact} variant="outlined" helperText={eSContact2HelperText}
                  error={eSContact2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Threshold(In Minutes)" id="esc2_threshold" value={esc2Threshold} variant="outlined" helperText={eSThreshold2HelperText} error={eSThreshold2HelperText} style={{ width: "400px" }} onChange={(e) => { setEsc2Threshold(e.target.value) }} />
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />

            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Level-3 Escalation Details (Optional)
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField label="Name" id="esc3_name" value={esc3Name} variant="outlined" style={{ width: "400px" }} helperText={eSCName3HelperText}
                  error={eSCName3HelperText} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Email" id="esc3_email" value={esc3Email} variant="outlined" helperText={eSEmail3HelperText}
                  error={!!eSEmail3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Contact" id="esc3_contact" value={esc3Contact} variant="outlined" helperText={eSContact3HelperText}
                  error={eSContact3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>

              <Grid item xs={6}>
                <TextField label="Threshold(In Minutes)" id="esc3_threshold" value={esc3Threshold} variant="outlined" helperText={eSThreshold3HelperText} error={eSThreshold3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />

            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h5'>
                  Site Parameters
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField label="Latency(in ms)" id="latency" value={latency === null ? "" : latency} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} helperText={latencyHelperText} error={!!latencyHelperText} required />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Jitter(in ms)" id="jitter" value={jitter === null ? "" : jitter} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} helperText={jitterHelperText} error={!!jitterHelperText} required />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Packet Loss(in %)" id="packet_loss" value={packetLoss === null ? "" : packetLoss} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} helperText={packetLossHelperText} error={!!packetLossHelperText} required />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="criticality-label-id">criticality</InputLabel>
                  <Select
                    labelId="criticality-label-id"
                    id="criticality_id"
                    value={criticality}
                    label="Criticality"
                    style={{ width: "400px" }}
                    onChange={(e) => { setCriticalityHelperText(""); setCriticality(e.target.value) }}
                  >
                    <MenuItem value={"0"}>Low</MenuItem>
                    <MenuItem value={"1"}>Medium</MenuItem>
                    <MenuItem value={"2"}>High</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: 'red' }}>{criticalityHelperText}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {/* <Divider sx={{ paddingTop: "20px" }} /> */}
            {/* <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={pauseStatus}
                    onChange={(e)=>{setPauseStatus(e.target.value)}}
                  >
                    <FormControlLabel value="0" control={<Radio />} label="Pause" disabled/>
                    <FormControlLabel value="1" control={<Radio />} label="Running" disabled/>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid> */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenEditDialog(false) }}>Cancel</Button>
          <Button onClick={handleOnUpdate} variant="contained" disabled={!isMandatoryFieldUpdated}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}