import React, { useState, useEffect, forwardRef } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Box,
  Checkbox,
  Autocomplete,
  FormControlLabel,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  FormGroup,
  RadioGroup
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomDateRangePicker from '../CustomDateRangePicker';
import AddTemplateApiCall from '../ReportApiCall/AddTemplateApiCall';
import GetBusinessUnitsApiCall from '../ReportApiCall/GetBusinessUnitsApiCall';
import GetZoneDetailsApiCall from '../ReportApiCall/GetZoneDetailsApiCall';
import GetGroupsApiCall from '../ReportApiCall/GetGroupsApiCall';
import GetLocationsApiCall from '../ReportApiCall/GetLocationsApiCall';

const CreateTemplateDialog = (props) => {

  const { openAddTemplateDialog, setOpenAddTemplateDialog, setAddTemplateStatus, setAddTemplateMessage } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [dependent, setDependent] = useState([]);

  const [selectedBuIds, setSelectedBuIds] = useState([]);
  const [selectedZoneIds, setSelectedZoneIds] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  const [reportName, setReportName] = useState("");
  const [category, setCategory] = useState("");
  const [addReportstatus, setAddReportstatus] = useState(0);
  const [byDate, setByDate] = useState("1")
  const [data24X7, setData24X7] = useState(true)
  const [busHrData, setBusHrData] = useState(false)

  const [customDateStart, setCustomDateStart] = useState(new Date())
  const [customDateEnd, setCustomDateEnd] = useState(new Date())

  const [customTimeStart, setCustomTimeStart] = useState(new Date());
  const [customTimeEnd, setCustomTimeEnd] = useState(new Date());
  const [errors, setErrors] = useState({});
  // const [addReportStatus, setAddReportstatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [reportByDate, setReportByDate] = useState('1');

  const [mon, setMon] = useState(false)
  const [tue, setTue] = useState(false)
  const [wed, setWed] = useState(false)
  const [thu, setThu] = useState(false)
  const [fri, setFri] = useState(false)
  const [sat, setSat] = useState(false)
  const [sun, setSun] = useState(false)
  const [businessUnits, setBusinessUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [groups, setGroups] = useState([]);
  const [locations, setLocations] = useState([]);
  const [disableDropdown, setDisableDropdown] = useState(true)
  const [nameHelperText, setNameHelperText] = useState("");


  const [categoryHelperText, setCategoryHelperText] = useState("");
  const [dateHelperText, setDateHelperText] = useState("");
  const [timeHelperText, setTimeHelperText] = useState("");
  const [selectStartDate, setSelectStartDate] = useState("");
  const [selectEndDate, setSelectEndDate] = useState("");

  const [selectDaywiseStartTime, setSelectDaywiseStartTime] = useState("");
  const [selectDaywiseEndTime, setSelectDaywiseEndTime] = useState("")
  const [categoryId, setCategoryId] = useState("");
  const [error, setError] = useState("");
  const [selectOptionHelperText, setSelectOptionHelperText] = useState("")
  const [businessZoneGroupLocationLabel, setBusinessZoneGroupLocationLabel] = useState("")
  const [listHelperText, setListHelperText] = useState("")
  const [dependentDropdown, setDependentDropdown] = useState("")
  const [dependentHelperText, setDependentHelperText] = useState('');



  // const reportNameRegex = /^[a-zA-Z0-9\s-_]+$/;
  // const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  // const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;


  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const resetAll = () => {
    setReportName("");
    setCategory("");
    setByDate("1");
    setData24X7(false);
    setBusHrData(false);
    setCustomDateStart(new Date());
    setCustomDateEnd(new Date());
    setCustomTimeStart(new Date());
    setCustomTimeEnd(new Date());
    setMon(false);
    setTue(false);
    setWed(false);
    setThu(false);
    setFri(false);
    setSat(false);
    setSun(false);
    setBusinessUnits([]);
    setZones([]);
    setGroups([]);
    setLocations([]);
    setSelectedOption("");
    setDependent("");
    setStartDate(null);
    setEndDate(null);
    setAddReportstatus(0);
    setReportName("");

    setError(false); 
  setNameHelperText(""); 
  setSelectOptionHelperText(""); 
  setCategoryHelperText(""); 
  };

  useEffect(() => {

    if (selectedOption === 'Business Units') {
      GetBusinessUnitsApiCall(setBusinessUnits);
    } else if (selectedOption === 'Zone') {
      GetZoneDetailsApiCall(setZones);
    } else if (selectedOption === 'Group') {
      GetGroupsApiCall(setGroups);
    } else if (selectedOption === 'Location') {
      GetLocationsApiCall(setLocations);
    }

    if (selectedOption !== 'Business Units') {
      setSelectedBuIds([]);
      setDependent([]);
    }
    if (selectedOption !== 'Zone') {
      setSelectedZoneIds([]);
    }
    if (selectedOption !== 'Group') {
      setSelectedGroupIds([]);
    }
    if (selectedOption !== 'Location') {
      setSelectedLocationIds([]);
    }

  }, [selectedOption]);

  const handleChange = (event) => {

    const { value } = event.target;
    setDependent(value);
    if (selectedOption && selectedOption !== "All") {
      if (value.length === 0) {
        setError(true);
        setDependentHelperText(`Please select at least one ${selectedOption}`);
      } else {
        setError(false);
        setDependentHelperText('');
      }
    }

    

    if (selectedOption === "Business Units") {
      const selectedBuIdsArray = businessUnits
        .filter((unit) => value.includes(unit.bu_name))
        .map((unit) => unit.bu_id);

      setSelectedBuIds(selectedBuIdsArray);
    }
    if (selectedOption === "Zone") {
      const selectedZoneIdsArray = zones
        .filter((zone) => value.includes(zone.zone_name))
        .map((zone) => zone.zone_id);

      setSelectedZoneIds(selectedZoneIdsArray);
    }
    if (selectedOption === "Group") {
      const selectedGroupIdsArray = groups
        .filter((group) => value.includes(group.group_name))
        .map((group) => group.group_id);

      setSelectedGroupIds(selectedGroupIdsArray);
    }

    if (selectedOption === "Location") {
      const selectedLocationIdsArray = locations
        .filter((location) => value.includes(location.location_name))
        .map((location) => location.location_id);

      setSelectedLocationIds(selectedLocationIdsArray);
    }
    
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetAll();
    setOpenSnackBar(false);
  };

  const handleOnClose = () => {
    setOpenAddTemplateDialog(false);
  }

  useEffect(() => {
  }, [errors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("Selected Option:", selectedOption);
    // console.log("Category ID:", categoryId);
    
    if (reportName === "") {
      setNameHelperText("Report Name Should be Valid");
      return;
    }
    
      if (selectedOption === "") {
        setError(true);
        setSelectOptionHelperText("Please select at least one option");
        return;
      }
    if (category === "") {
      setError(true);
      setCategoryHelperText("Please select a  category");
      return;
    }
    
    
    if (selectedOption && selectedOption !== "All" && dependent.length === 0) {
      setError(true);
      setDependentHelperText(`Please select at least one ${selectedOption}`);
      return;
    }

    // Proceed with form submission if no errors
    const payload = {
      reportName,
      category,
      byDate,
      data24X7: data24X7 ? 1 : 0,
      busHrData: busHrData ? 1 : 0,
      startDate,
      endDate,
      customDateStart,
      customDateEnd,
      customTimeStart,
      customTimeEnd,
      reportByDate,
      exclDays: [
        mon && "Monday",
        tue && "Tuesday",
        wed && "Wednesday",
        thu && "Thursday",
        fri && "Friday",
        sat && "Saturday",
        sun && "Sunday",
      ].filter(Boolean).join(","),
      bu_ids: selectedBuIds,
      zone_ids: selectedZoneIds,
      group_ids: selectedGroupIds,
      location_ids: selectedLocationIds,
    };
 
    AddTemplateApiCall(payload, setOpenSnackBar, setAddReportstatus, setAddTemplateStatus, setAddTemplateMessage);
    setOpenAddTemplateDialog(false);
    setAddReportstatus(1);
    setOpenSnackBar(true);
    setErrors({});

  };


  return (
    <>
      <Dialog open={openAddTemplateDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
        <DialogTitle sx={{ py: 2 }}>
          Generate New Report
          <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 3 }}>
          <form onSubmit={handleSubmit} style={{ width: '80%' }}>
            <Box sx={{ px: 10, py: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>

                  <TextField // 1st field  Name
                    id="name"
                    name="name"
                    label="Name"
                    value={reportName}
                    onChange={(e) => 
                              { setNameHelperText("");
                                setReportName(e.target.value) 
                              }
                            }
                    sx={{ width: 400 }}
                    helperText={nameHelperText}
                    error={!!nameHelperText}
                  />
                </Grid>


                <Grid item xs={12} >
                  <FormControl fullWidth error={error}>
                    <InputLabel id="businessZoneGroupLocationLabel">Select Option</InputLabel>
                    <Select
                      labelId="businessZoneGroupLocationLabel"
                      id="businessZoneGroupLocationLabel"
                      value={selectedOption}
                      onChange={(e) => {
                        if (e.target.value !== "All") {
                          setDisableDropdown(false)
                        } else {
                          setDisableDropdown(true)
                        }
                        setSelectedOption(e.target.value)
                        if (e.target.value !== "") {
                          setError(false);
                          setSelectOptionHelperText('');
                        }
                      }}
                      label="Select Option"
                      style={{ width: "400px" }}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Business Units">Business Units</MenuItem>
                      <MenuItem value="Zone">Zone</MenuItem>
                      <MenuItem value="Group">Group</MenuItem>
                      <MenuItem value="Location">Location</MenuItem>
                    </Select>
                    {error && <Typography variant="caption" color="error">{selectOptionHelperText}</Typography>}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" error={error}>
                    <InputLabel id="dependent-dropdown-label">
                      {selectedOption ? `Select ${selectedOption}` : "List"}
                    </InputLabel>
                    <Select
                      labelId="dependent-dropdown-label"
                      id="dependentDropdown"
                      multiple
                      value={dependent}
                      onChange={handleChange}
                      label={selectedOption ? `Select ${selectedOption}` : "List"}
                      style={{ width: "400px" }}
                      disabled={disableDropdown}

                    >
                      {selectedOption === "Business Units" &&
                        businessUnits?.map((unit) => (
                          <MenuItem key={unit.bu_id} value={unit.bu_name}>
                            {unit.bu_name}
                          </MenuItem>
                        ))
                      }

                      {selectedOption === "Zone" &&
                        zones?.map((zone) => (
                          <MenuItem key={zone?.zone_id} value={zone?.zone_name}>
                            {zone?.zone_name}
                          </MenuItem>
                        ))
                      }

                      {selectedOption === 'Group' &&
                        groups?.map((group) => (
                          <MenuItem key={group?.group_id} value={group?.group_name}>
                            {group?.group_name}
                          </MenuItem>
                        ))
                      }

                      {selectedOption === "Location" &&
                        locations?.map((location) => (
                          <MenuItem key={location?.location_id} value={location?.location_name}>
                            {location?.location_name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    {error && <Typography variant="caption" color="error">{dependentHelperText}</Typography>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="select_range_by"
                      defaultValue="1"
                      name="radio-buttons-group"
                      onChange={(e) => {
                        setByDate(e.target.value);
                      }
                      }
                      row
                    >
                      <FormControlLabel value="1" control={<Radio />} label="By Date Range" />
                      <FormControlLabel value="0" control={<Radio />} label="Custom Range" />
                    </RadioGroup>
                  </FormControl>
                </Grid>



                {/* 2end Field Category */}
                <Grid item xs={12}>
                  <FormControl fullWidth error={error}>
                    <InputLabel id="category-label-id">Category</InputLabel>
                    <Select
                      labelId="category-label-id"
                      id="categoryId"
                      value={category}
                      onChange={(e) => { setCategoryHelperText("");
                        setCategory(e.target.value);
                        setError(false);
                       }}
                      label="Category"
                      style={{ width: "400px" }}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value='LINK'>Link Availability</MenuItem>
                      <MenuItem value='SITE'>Site Availability</MenuItem>
                      <MenuItem value='DEVICE'>Device Availability</MenuItem>
                      {byDate === '0' && <MenuItem value='UTILIZATION'>Link Utilization</MenuItem>}
                    </Select>
                    {error && <Typography variant="caption" color="error">{categoryHelperText}</Typography>}
                  </FormControl>
                </Grid>



                {byDate === "1" && <Grid item xs={12}>
                  <FormControl>
                    <FormGroup row> {/* This prop makes the checkboxes horizontal */}
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            name="dateRange"
                            checked={data24X7}
                            onChange={(e) => { setData24X7(!data24X7) }}
                          />}
                        label="24X7 Data"

                      />
                      {/* <FormControlLabel
                        control={<Checkbox
                          name="busHrData"
                          checked={busHrData}
                          onChange={(e) => { setBusHrData(!busHrData) }}
                        />}
                        label="Business Hr Data"
                      /> */}
                    </FormGroup>
                  </FormControl>
                </Grid>}

                {byDate === "1" && (<Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePicker
                          label="Select Start Date"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          helperText={setDateHelperText}
                          error={!!setDateHelperText}
                        />
                        <DatePicker
                          label="Select End Date"
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          error={!!setDateHelperText}
                          helperText={setDateHelperText}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                </Grid>)}

                {byDate === "0" && (<Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePicker
                          label="Select Start Date"
                          value={customDateStart}
                          onChange={(newValue) => setCustomDateStart(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          error={!!setDateHelperText}
                          helperText={setDateHelperText}
                        />
                        <DatePicker
                          label="Select End Date"
                          value={customDateEnd}
                          onChange={(newValue) => setCustomDateEnd(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          error={!!setDateHelperText}
                          helperText={setDateHelperText}

                        />
                      </div>
                      <div style={{ display: 'flex', gap: '10px', paddingTop: "10px" }}>
                        <TimePicker
                          label="Select daywise start time"
                          value={customTimeStart}
                          onChange={(newValue) => setCustomTimeStart(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          error={!!setTimeHelperText}
                          helperText={setTimeHelperText}
                        />
                        <TimePicker
                          label="Select daywise End Time"
                          value={customTimeEnd}
                          onChange={(newValue) => setCustomTimeEnd(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          error={!!setTimeHelperText}
                          helperText={setTimeHelperText}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                </Grid>)}

                {byDate === "0" && category === 'UTILIZATION' &&<Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="report_by_date"
                      name="radio-buttons-group"
                      onChange={(e) => {
                        setReportByDate(e.target.value);
                      }
                      }
                      row
                    >
                      <FormControlLabel value="1" control={<Radio />} label="Daywise Report" />
                      <FormControlLabel value="0" control={<Radio />} label="Consolidated" />
                    </RadioGroup>
                  </FormControl>
                </Grid>}

                {byDate === "0" && <Grid item xs={12}>
                  <Typography>
                    <strong>Select Days to be Excluded</strong>
                  </Typography>
                </Grid>}

                {byDate === "0" && <Grid item xs={12}>
                  <FormControl>
                    <FormGroup row> {/* This prop makes the checkboxes horizontal */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Monday"
                            checked={mon}
                              onChange={() => { setMon(!mon) }}
                            />}
                            
                        label="Mon"

                      />
                      <FormControlLabel
                        control={<Checkbox
                          name="Tuesday"
                          checked={tue}
                          onChange={() => { setTue(!tue) }}
                        />}
                        label="Tues"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          name="Wednesday"
                          checked={wed}
                          onChange={() => { setWed(!wed) }}
                        />}
                        label="Wed"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          name="Thursday"
                          checked={thu}
                          onChange={() => { setThu(!thu) }}
                        />}
                        label="Thu"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          name="Friday"
                          checked={fri}
                          onChange={() => { setFri(!fri) }}
                        />}
                        label="Fri"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          name="Saturday"
                          checked={sat}
                          onChange={() => { setSat(!sat) }}
                        />}
                        label="Sat"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          name="Sunday"
                          checked={sun}
                          onChange={() => { setSun(!sun)}}
                        />}
                        label="Sun"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>}

              </Grid>

            </Box>
          </form>
          {/* <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={addReportstatus === 1 ? "success" : "error"} sx={{ width: '100%' }}>
              <Typography>{addReportstatus === 1 ? "Template created Successfully." : "Error in creating Template"}</Typography>
            </Alert>
          </Snackbar> */}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog >
      {errorMessage && (
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CreateTemplateDialog;
