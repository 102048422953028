import React, { useState, createRef, useEffect } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { CSVLink } from 'react-csv';
import {
    Breadcrumbs, Container, Link, Grid, TextField, Button, Alert, AlertTitle, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Modal, Box, Fade, Typography,
    Paper, Dialog, DialogTitle, DialogContent, DialogActions, Pagination
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import FileUpload from "react-material-file-upload";
import Papa from "papaparse";
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

// api file here
import UploadMultipleSiteApiCall from './UploadMultipleSiteApiCall';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};
const theme = createTheme({
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#D0D0D0',
                    },
                    '&.MuiTableRow-head': {
                        backgroundColor: '#D0D0D0',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: 'Black',
                    backgroundColor: '#D0D0D0',
                    fontSize: '15px'
                },
            },
        },
    },
});
const useStyles = makeStyles((theme) => ({
    // redBorderCell: {
    //   border: '1px solid red',
    // },
    redText: {
        color: 'red',
        fontWeight: 'bold'
    }
}));
// const usePageStyles = makeStyles((theme) => ({
//     root: {
//         '& .MuiPaginationItem-root': {
//             color: theme.palette.text.primary,
//         },
//         '& .Mui-selected': {
//             backgroundColor: theme.palette.primary.main,
//             color: theme.palette.common.white,
//         },
//         '& .Mui-disabled': {
//             color: theme.palette.text.disabled,
//         }
//     }
// }));
const usePageStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaginationItem-root': {
            color: theme.palette.text.primary, 
        },
        '& .MuiPaginationItem-loaded': {
            backgroundColor: theme.palette.grey[900], 
            color: theme.palette.common.white,
        },
        '& .MuiPaginationItem-yetToLoad': {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.text.disabled,
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '& .Mui-disabled': {
            color: theme.palette.text.disabled,
        }
    }
}));



export default function MultipleSiteUploadDialog(props) {

    const classes = useStyles();

    const { value, setOpens, openMultipleSiteUploadDialog, setOpenMultipleUploadDialog, setSeverStatus, setMultipleSiteUploadStatus, setMultipleSiteUploadMessage } = props

    // variables
    const [files, setFiles] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [data, setData] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [formatError, setFormatError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [hasEdits, setHasEdits] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);
  const rowsPerPage = 30; 
  const classesPage = usePageStyles();


    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setFiles([]);
        setCsvData([]);
        setDeviceData([]);
        setData([]);
        setValidationError(false);
        setOpen(false);
        setOpenMultipleUploadDialog(false);
    };

    const multiSiteLink = createRef();
    const supDeviceLink = createRef();

    // regex

    const locationRegex = /^[a-zA-Z0-9(][a-zA-Z0-9 _(),-]*(?<![-_( ])$/;
    const ipRegEx = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    const nameRegex = /^[a-zA-Z0-9(][a-zA-Z0-9 _(),-]*(?<![-_( ])$/;
    const portRegex = /^\d+$/;
    const snmpVersionRegex = /^(1|2|3)$/;
const expectedHeaders = [
        "Location", "City", "State", "Country", "Device_Ip", "Snmp_String",
        "Snmp_Version", "SSH_Username", "Password", "SSH_Port", "Api_Key",
        "Api_Port", "Device", "Type"
    ];


    const handleSave = () => {
        
        const file = files[0];
        if (!file) return "";
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true, });
            const parsedData = csv?.data;
            setValidationError(false);

            setFormatError(false);

            const headers = csv?.meta?.fields;
            const formatValid = checkFormat(headers);
            setFormatError(!formatValid);

            if (!formatValid) {
                setOpen(true);
                setValidationMessage("CSV format is incorrect. Please upload the file with the correct headers.");
                return;
            }

            if (parsedData.length === 0) {
                setOpen(true);
                setValidationMessage("No data available in the uploaded file.");
                return;
            }
            if (parsedData.length > 200) {
                setOpen(true);
                setValidationMessage("The file contains more than 200 lines. Please upload a file with 200 or fewer lines.");
                return;
            }
            let hasError = false;
            parsedData.forEach(row => {
                Object.entries(row).forEach(([key, value]) => {

                    if (// fields
                        (key === 'Location' && !locationRegex.test(value.trim())) ||
                        (key === 'City' && !locationRegex.test(value.trim())) ||
                        (key === 'State' && !locationRegex.test(value.trim())) ||
                        (key === 'Country' && !locationRegex.test(value.trim())) ||
                        (key === 'Device_Ip' && !ipRegEx.test(value.trim())) ||
                        (key === 'Snmp_String' && row.Snmp_String.trim() === "") ||
                        (key === 'Snmp_Version' && !snmpVersionRegex.test(value.trim())) ||
                        (key === 'SSH_Username' && !nameRegex.test(value.trim())) ||
                        (key === 'Password' && row.Password.trim() === "") ||
                        (key === 'SSH_Port' && !portRegex.test(value.trim())) ||
                        // (key === 'Api_Key' && row.Api_Key.trim() === "") ||
                        // (key === 'Api_Port' && !portRegex.test(value.trim())) ||
                        (key === 'Device' && row.Device.trim() === "") ||
                        (key === 'Type' && row.Type.trim() === "")
                    ) {
                        hasError = true;
                        setValidationError(true);
                    }
                });
            });
            if (validationError) return;
            setValidationError(hasError);
            setIsValid(!hasError && parsedData.length > 0);
            setData(parsedData);
            setOpen(true);


        };
        reader.readAsText(file);
    }
   
    const checkFormat = (headers) => {
        if (headers.length !== expectedHeaders.length) return false;

        for (let i = 0; i < headers.length; i += 1) {
            if (headers[i].trim() !== expectedHeaders[i].trim()) {
                return false;
            }
        }
        return true;
    };
   
    const handleFieldChange = (rowIndex, key, value) => {
        const updatedData = [...data];
        updatedData[rowIndex][key] = value;
        //  setData(updatedData);

        // setHasEdits(true);
    
        const updatedValidation = updatedData.map((row) => validateRow(row));
    
        const hasError = updatedValidation.some((validation) => validation === true);
        
        setValidationError(hasError);
        setIsValid(!hasError);
        setData(updatedData);
    };
    
    const validateRow = (row) => {
        return Object.entries(row).some(([key, value]) => (
            (key === 'Location' && !locationRegex.test(value.trim())) ||
            (key === 'City' && !locationRegex.test(value.trim())) ||
            (key === 'State' && !locationRegex.test(value.trim())) ||
            (key === 'Country' && !locationRegex.test(value.trim())) ||
            (key === 'Device_Ip' && !ipRegEx.test(value.trim())) ||
            (key === 'Snmp_String' && row.Snmp_String.trim() === "") ||
            (key === 'Snmp_Version' && row.Snmp_Version.trim() === "") ||
            (key === 'SSH_Username' && !nameRegex.test(value.trim())) ||
            (key === 'Password' && row.Password.trim() === "") ||
            (key === 'SSH_Port' && !portRegex.test(value.trim())) ||
            (key === 'Device' && row.Device.trim() === "") ||
            (key === 'Type' && row.Type.trim() === "")
        ));
    };
    
   
//     const handleOk = () => {
        
        
//         // const csv = Papa.unparse(data);
//         // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//         // const link = document.createElement("a");
//         // const url = URL.createObjectURL(blob);
//         // link.setAttribute("href", url);
//         // link.setAttribute("download", "updated_MultiSitedata.csv");

//         // document.body.appendChild(link);
//         // link.click();
//         // document.body.removeChild(link);
//         // console.log("File download initiated.");


//         // setHasEdits(false);

//         UploadMultipleSiteApiCall(data, setOpenMultipleUploadDialog, setSeverStatus, setMultipleSiteUploadStatus, setMultipleSiteUploadMessage);
//         console.log("api initiated")
//         setOpens(true)
// };
const handleOk = () => {
    setIsLoading(true);

    UploadMultipleSiteApiCall(data, setOpenMultipleUploadDialog, setSeverStatus, setMultipleSiteUploadStatus, setMultipleSiteUploadMessage)
        .then(() => {
            setOpens(true);
        })
        .catch((error) => {
            console.error('Error in handleOk:', error);
        })
        .finally(() => {
            setIsLoading(false); // Hide the loader
        });
};

    useEffect(() => {
        createDownLoadData();
        createSupportedDevicesCSV();
    }, [])
    const createSupportedDevicesCSV = () => {
        const data = [
            { "Vendor": "Fortinet", "Type": "Firewall" },
            { "Vendor": "SOPHOS", "Type": "Firewall" },
            { "Vendor": "Cisco", "Type": "Router" },
            { "Vendor": "Aruba", "Type": "SD WAN Box" },
            { "Vendor": "Versa", "Type": "SD WAN Box" },
            { "Vendor": "Meraki", "Type": "SD WAN Box" },
            { "Vendor": "Mist", "Type": "SD WAN Box" },
            { "Vendor": "Silverpeak", "Type": "SD WAN Box" },
            { "Vendor": "Viptella", "Type": "SD WAN Box" }
        ]
        setDeviceData(data);
    }
    const createDownLoadData = () => {

        const formateData = [
            ["Location", "City", "State", "Country", "Device_Ip", "Snmp_String", "Snmp_Version", "SSH_Username", "Password", "SSH_Port", "Api_Key", "Api_Port", "Device", "Type"],
            ["HSR Layout", "Bengaluru", "Karnataka", "India", "10.10.10.10", "Zomato", "2", "Admin_user", "Pass@123", "22", "abc123", "250", "Fortinet", "Firewall"],
            ["Data Center A", "San Francisco", "CA", "USA", "192.168.1.1", "public", "2", "admin", "password123", "22", "apikey12345", "8080", "Cisco", "Router"]
        ]

        setCsvData(formateData);
    }
    const handleDownload = () => {
        multiSiteLink.current.link.click();
    }

    const handleDeviceClick = () => {
        supDeviceLink.current.link.click();
    }
    
    useEffect(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        setPaginatedData(data.slice(startIndex, endIndex));
    }, [currentPage, data, rowsPerPage]);
    
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <Dialog open={openMultipleSiteUploadDialog} onClose={() => { setOpenMultipleUploadDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Upload Multiple Locations With Devices
                    <IconButton onClick={() => { setOpenMultipleUploadDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 6, px: 3 }}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '25vh' }}
                    >

                        <FileUpload sx={{ width: "100%", height: "100%" }} alignItems="center" value={files} onChange={setFiles} accept={".csv"} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CSVLink data={csvData} filename={`multipleSite_format.csv`} ref={multiSiteLink}>{ }</CSVLink>
                    <CSVLink data={deviceData} filename={`supported_devices.csv`} ref={supDeviceLink}>{ }</CSVLink>
                    <Button variant="contained" onClick={handleDeviceClick} startIcon={<DownloadIcon />} Upload>Supported Devices</Button>
                    <Button variant="contained" onClick={handleDownload} startIcon={<DownloadIcon />} Upload>Format</Button>
                    <Button variant="contained" onClick={handleSave} >Save</Button>
                    <Button variant="contained" onClick={() => { setOpenMultipleUploadDialog(false) }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                sx={{ overflowX: 'auto' }}
            >
                <Fade in={open}>
                    <Box sx={{ ...style, maxHeight: 800 }}>
                        <Typography id="transition-modal-title" variant="h6" component="h2" >
                            Uploading Multiple Locations Preview
                        </Typography>
                        {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) } 
                    {!isLoading && data.length > 0 ? (
                            <>
                            <TableContainer component={Paper} sx={{ marginTop: '5%', maxHeight: 400, overflow: 'auto', scrollbarWidth: 'thin' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {data && data.length > 0 && (
                                        <TableHead>
                                            <ThemeProvider theme={theme}>
                                                <TableRow sx={{ bgcolor: '#D3D3D3' }}>
                                                    {Object.keys(data[0]).map((key, index) => (
                                                        <TableCell key={index}>{key}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </ThemeProvider>
                                        </TableHead>
                                    )}
                                    <TableBody>
                                        {/* {data && data.length > 0 && data.map((row, rowIndex) */}
                                        {paginatedData.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {Object.entries(row).map(([key, cell], cellIndex) => {
                                                    const isEmptyOrInvalid = !cell || (
                                                        (key === 'Location' && !locationRegex.test(cell.trim())) ||
                                                        (key === 'City' && !locationRegex.test(cell.trim())) ||
                                                        (key === 'State' && !locationRegex.test(cell.trim())) ||
                                                        (key === 'Country' && !locationRegex.test(cell.trim())) ||
                                                        (key === 'Device_Ip' && !ipRegEx.test(cell.trim())) ||
                                                        (key === 'Snmp_String' && cell.trim() === "") ||
                                                        (key === 'Snmp_Version' &&  !snmpVersionRegex.test(cell.trim())) ||
                                                        (key === 'SSH_Username' && !nameRegex.test(cell.trim())) ||
                                                        (key === 'Password' && cell.trim() === "") ||
                                                        (key === 'SSH_Port' && !portRegex.test(cell.trim())) ||
                                                        (key === 'Api_Key' && cell.trim() === "") ||
                                                        (key === 'Api_Port' && !portRegex.test(cell.trim())) ||
                                                        (key === 'Device' && cell.trim() === "") ||
                                                        (key === 'Type' && cell.trim() === "")
                                                    );
                                                    return (
                                                        // <TableCell key={cellIndex} style={{ color: isEmptyOrInvalid ? 'red' : 'inherit' }}>
                                                        //     {isEmptyOrInvalid ? 'Details Missing!' : cell}
                                                        // </TableCell>
                                                        <TableCell key={cellIndex} style={{
                                                            color: isEmptyOrInvalid ? 'red' : 'inherit',
                                                            minWidth: '150px',  
                                                            whiteSpace: 'nowrap',  
                                                            overflow: 'hidden',  
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            <TextField
                                                                value={cell || ""}  
                                                                onChange={(e) => handleFieldChange(
                                                                    // rowIndex, key, e.target.value)
                                                                    (currentPage - 1) * rowsPerPage + rowIndex, key, e.target.value)
                                                                }  
                                                                error={isEmptyOrInvalid}  
                                                                helperText={isEmptyOrInvalid ? 'Details Missing!' : ''}  
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                            <Pagination
                                count={Math.ceil(data.length / rowsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                classesPage ={classesPage}
                            />
                        </Box>
                        </>
                        ) : (
                            <Typography sx={{ mt: 20, color: 'red', fontSize: '20px', textAlign: 'center' }}>
                                {/* No data available in the uploaded file. */}
                                {validationMessage}
                            </Typography>
                        )}
                        <Box sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Button onClick={handleClose} sx={{ marginRight: 2 }}>Cancel</Button>
                            <Button onClick={handleOk} variant="contained" color="primary" disabled={validationError || data.length === 0 || currentPage !== Math.ceil(data.length / rowsPerPage)}>Ok</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )

}