import React, { useEffect, useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Iconify from '../../Iconify';

import GetVendorDetailsApiCall from './GetVendorDetailsApiCall';
import AddNewVendorDialog from './AddNewVendorDialog';
import DeleteVendorDialog from './DeleteVendorDialog';
import EditVendorDialog from './EditVendorDialog';

import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';



const useStyles = makeStyles({
    container: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px', // Adjust the width as desired
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
    },
});

const headCells = [
    {
        id: 'slNo',
        numeric: true,
        disablePadding: true,
        label: 'Sl No',
    },
    {
        id: 'cust_name',
        numeric: false,
        disablePadding: true,
        label: 'Customer',
    },
    {
        id: 'vendorName',
        numeric: false,
        disablePadding: true,
        label: 'Vendor Name',
    },
    {
        id: 'vendorContactNo',
        numeric: false,
        disablePadding: false,
        label: 'Contact No',
    },
    {
        id: 'helpDeskMail',
        numeric: false,
        disablePadding: false,
        label: 'Help Desk Mail',
    },
    {
        id: 'Escalation Level-1 Mail',
        numeric: false,
        disablePadding: false,
        label: 'Escalation Level-1 Mail',
    },
    {
        id: 'Escalation Level-2 Mail',
        numeric: false,
        disablePadding: false,
        label: 'Escalation Level-2 Mail',
    },
    {
        id: 'promisedUptime',
        numeric: false,
        disablePadding: false,
        label: 'Promised Uptime',
    },
    {
        id: 'resolutionTime',
        numeric: false,
        disablePadding: false,
        label: 'Resolution Time',
    },
    
    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead({ showCustomer, tagDataKeys }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.slice(0, 9).map((headCell) => (
                    (headCell.id !== 'cust_name' || showCustomer) &&
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
                {tagDataKeys.map(key => (
            <TableCell
                key={key}
                align="left"
                padding="normal"
                sx={{ background: '#f0f0f0', padding: '10px' }}
            >
                {key} {/* Display tag_data key as the column header */}
            </TableCell>
        ))}
        {headCells.slice(9).map((headCell) => (
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    showCustomer: PropTypes.bool.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    tagDataKeys: PropTypes.array.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName, handleUploadClick, handleAddClick }) {
    return (
        <RootStyle>
            <Typography variant='h4'>
                Vendors
            </Typography>
            <Box
                width="78%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
            >
                {/* <Button 
                        variant="outlined" 
                        startIcon={<UploadIcon />}
                        sx={{width:"150px", height:"40px"}}
                        onClick={handleUploadClick}>
                        Upload 
                    </Button> */}
                <Box
                    width="10px"
                />
                <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    sx={{ width: "150px", height: "40px", paddingLeft: "10px" }}
                    onClick={handleAddClick}>
                    New
                </Button>
            </Box>

            <SearchStyle
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function VendorTable() {

    const { globalState, setGlobalState } = useGlobalState();

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspClientId = localStorage.getItem("cust_id");
    const regClientId = localStorage.getItem("reg_cust_id");
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const isEngineer = (localStorage.getItem("role") === "2")
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [value, setValue] = useState({});

    const [openAddVendorDialog, setOpenAddVendorDialog] = useState(false);
    const [addVendorStatus, setAddVendorStatus] = useState(0);

    const [openDeleteVendorDialog, setOpenDeleteVendorDialog] = useState(false);
    const [deleteVendorStatus, setDeleteVendorStatus] = useState(0);

    const [openEditVendorDialog, setOpenEditVendorDialog] = useState(false);
    const [editVendorStatus, setEditVendorStatus] = useState(0);

    const [addVendorMessage, setAddVendorMessage] = useState("");

    const [editVendorMessage, setEditVendorMessage] = useState("");

    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));


    const handleOnEdit = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenEditVendorDialog(true);
    }

    const handleDelete = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenDeleteVendorDialog(true);
    }
    
    const handleFilterByName = async (event, searchedVal) => {
        setFilterName(event.target.value)
        setFilteredRows(rows);
        const ftrRows = rows.filter((row) => {
            return row.vendor_name.toLowerCase().includes(searchedVal.toLowerCase())
        });
        setFilteredRows(ftrRows);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const _GetVendorDetailsApiCall = async () => {
        await GetVendorDetailsApiCall(setRows, setFilteredRows);
    }

    useEffect(() => {
        _GetVendorDetailsApiCall();
    }, [addVendorStatus, deleteVendorStatus, editVendorStatus, globalState])

    const resetAll = () => {
        setAddVendorStatus(0);
        setOpenAddVendorDialog(false);

        setDeleteVendorStatus(0);
        setOpenDeleteVendorDialog(false);

        setEditVendorStatus(0);
        setOpenEditVendorDialog(false);
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAll();
    };

    const handleUploadClick = () => {
        resetAll();
        setValue(rows);
    }

    const handleAddClick = () => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setOpenAddVendorDialog(true);
    }
   
    const tagDataKeys = [...new Set(filteredRows.flatMap(row => Object.keys(row.tag_data || {})))];

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar
                    filterName={filterName}
                    onFilterName={(e) => { handleFilterByName(e, filterName) }}
                    handleUploadClick={handleUploadClick}
                    handleAddClick={handleAddClick}
                />
                <TableContainer className={classes.container}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            showCustomer={mspClientId === regClientId && mspStatus === 1}
                            numSelected={selected.length}
                            rowCount={filteredRows.length}
                            tagDataKeys={tagDataKeys} 
                        />
                        <TableBody>
                            {filteredRows.map((row, index) => {
                                // const tagDataEntries = Object.entries(row.tag_data);
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.zone_id}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        {mspClientId === regClientId && mspStatus === 1 ? (<TableCell align="left">{row.cust_name}</TableCell>) : null}
                                        <TableCell align="left">{row.vendor_name}</TableCell>
                                        <TableCell align="left">{row.vendor_phone_no} {row.last_name}</TableCell>
                                        <TableCell align="left">{row.helpdesk_email}</TableCell>
                                        <TableCell align="left">{row.escalation_email_1}</TableCell>
                                        <TableCell align="left">{row.escalation_email_2}</TableCell>
                                        <TableCell align="left">{row.promised_uptime} %</TableCell>
                                        <TableCell align="left">{row.resolution_time} Hrs</TableCell>
                                    
                                        
                                         {tagDataKeys.map(key => (
                                    <TableCell key={key}>
                                        {row.tag_data[key] || ''} 
                                    </TableCell>
                                ))}
                                        <TableCell align="center">
                                            <Grid direction="row">
                                                <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton>
                                                {!isEngineer && <IconButton variant="outlined" color="error" onClick={(e) => { handleDelete(e, row) }}><DeleteIcon /></IconButton>}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {openAddVendorDialog && <AddNewVendorDialog openAddVendorDialog={openAddVendorDialog} setOpenAddVendorDialog={setOpenAddVendorDialog} setAddVendorStatus={setAddVendorStatus} setAddVendorMessage={setAddVendorMessage} />}
                {openDeleteVendorDialog && <DeleteVendorDialog value={value} openDeleteVendorDialog={openDeleteVendorDialog} setOpenDeleteVendorDialog={setOpenDeleteVendorDialog} setDeleteVendorStatus={setDeleteVendorStatus} />}
                {openEditVendorDialog && <EditVendorDialog value={value} openEditVendorDialog={openEditVendorDialog} setOpenEditVendorDialog={setOpenEditVendorDialog} setEditVendorStatus={setEditVendorStatus} setEditVendorMessage={setEditVendorMessage} />}

                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
            </Paper>

            {addVendorStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {addVendorMessage}
                </Alert>
            </Snackbar>}
            {addVendorStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {addVendorMessage}
                </Alert>
            </Snackbar>}

            {deleteVendorStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    Vendor Deleted Successfully
                </Alert>
            </Snackbar>}
            {deleteVendorStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error in deleting Vendor
                </Alert>
            </Snackbar>}

            {editVendorStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {editVendorMessage}
                </Alert>
            </Snackbar>}
            {editVendorStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {editVendorMessage}
                </Alert>
            </Snackbar>}


        </Box>
    );
}